<template>

    <v-row justify="center">
        <v-col cols="12" sm="6">
            <v-card elevation="1">
                <v-card-title class="pb-2">
                    <template v-if="serverError">
                        <v-icon left color="error" class="mr-4">
                            mdi-alert-circle-outline
                        </v-icon>
                        <h2 class="title error--text">{{ serverMessage }}</h2>
                    </template>
                    <v-progress-linear
                        bottom
                        absolute
                        indeterminate
                        color="primary"
                        v-if="loading && !serverError"
                    ></v-progress-linear>
                </v-card-title>
                <v-card-actions v-if="serverError">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" v-if="!tryagain" @click="clearClick">
                        Stäng
                    </v-btn>
                    <v-btn text color="primary" @click="tryAgainClick" v-else>
                        Försök igen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: "LoadOrError",
        props: {loading: Boolean, serverMessage: null, tryagain: Boolean, serverError: Boolean},
        data() {
            return {};
        },

        watch: {
            serverError() {
                window.scrollTo(0, 0);

            }
        },
        methods: {
            clearClick(){
                this.$emit('clear:error')
            },
            tryAgainClick(){
                this.$emit('tryagain');
                this.$emit('clear:error')
            }
        }

    }
</script>

<style scoped>

</style>
