<template>
    <v-layout align-center row wrap v-if="visible">
        <v-col cols="12">
            <div>
                <v-text-field
                    v-model.trim="number"
                    @input="updateNumber"
                    label="Antal"
                    type="number"
                    outlined
                    min="0"
                >
                </v-text-field>
            </div>
        </v-col>
    </v-layout>
</template>

<script>

import {mapGetters, mapMutations} from 'vuex';

const NUMBER_METADATA_ID = 11;

export default {
    name: "NumberMetadata",
    components: {},
    props: ['metadata', 'category'],
    data: () => ({
        number: null,
    }),
    watch: {
        category() {
            this.number = null
            if (this.visible) {
                this.number = this.currentNumber
            }
        }
    },
    mounted() {
        this.number = null
        if (this.visible) {
            this.number = this.currentNumber
        }
    },
    computed: {
        ...mapGetters(['selectedRevisionMetadata']),

        currentMetadata() {
            return this.selectedRevisionMetadata(NUMBER_METADATA_ID, this.category)
        },
        currentNumber() {
            return this.selectedRevisionMetadata(NUMBER_METADATA_ID, this.category)?.json_data?.number ?? null;
        },
        hasNumberMetadata() {
            return this.selectedRevisionMetadata(NUMBER_METADATA_ID, this.category)?.json_data ?? false
        },
        visible() {
            return this.metadata && this.metadata.some(o => o.id == NUMBER_METADATA_ID)
        },
    },
    methods: {
        updateNumber(num) {
            if (this.hasNumberMetadata) {
                let metadata = _.cloneDeep(this.currentMetadata)
                metadata.json_data.number = num
                this.updateMetadata(metadata)
            } else {
                this.createMetadata({
                    id: this.obtainNegativeSerialId(),
                    equipment_category_id: this.category,
                    metadata_type_id: NUMBER_METADATA_ID,
                    json_data: {number: this.number}
                })
            }
        },
        ...mapMutations([
            'createMetadata', 'updateMetadata'
        ]),
    },
}
</script>

<style scoped>

</style>
