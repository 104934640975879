<template>

    <v-layout row wrap accent lighten-4 elevation-6 pa-2 align-center v-if="visible" @click="visible = false">
        <v-flex xs2 black--text font-weight-thin headline text-truncate>
            DEBUG
        </v-flex>
        <v-flex xs10 body-1>
            Λ({{ positionUpdates }} | {{ accuracy }} | {{ location.lat }} | {{ location.lng }})
        </v-flex>
    </v-layout>

</template>

<script>

    import {mapGetters, mapState} from 'vuex'

    export default {
        name: "DebugHeader",
        data: () => ({
            visible: true,
        }),
        computed: {
            ...mapState(['positionUpdates', 'position']),
            ...mapGetters(['location', 'accuracy'])
        }

    }
</script>

<style scoped>

</style>
