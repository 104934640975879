<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div v-if="questionHasCommentMetadata">
        <v-dialog v-model="dialog" max-width="600px">
            <template v-slot:activator="{ on }">
                <v-btn icon color="primary" v-on="on" large elevation="1">
                    <template v-if="questionHasCommentContent">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="28" height="28" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
                            <path fill="#3F51B5" d="M9,22c-0.6,0-1-0.4-1-1v-3H4c-1.1,0-2-0.9-2-2V4c0-1.1,0.9-2,2-2h16c1.1,0,2,0.9,2,2v12c0,1.1-0.9,2-2,2
                                h-6.1l-3.7,3.7C10,21.9,9.8,22,9.5,22l0,0H9 M10,16v3.1l3.1-3.1H20V4H4v12H10 M6,7h12v2H6V7 M6,11h9v2H6V11z"/>
                            <circle fill="#D32F2F" cx="19.6" cy="4.5" r="3.9"/>
                        </svg>
                    </template>
                    <template v-else>
                        <v-icon>mdi-comment-plus-outline</v-icon>
                    </template>
                </v-btn>
            </template>
            <v-card>
                <v-card-title>
                </v-card-title>
                <v-card-text>
                    <h4 id="label-input-comment" class="subtitle-1 mb-1">Kommentar</h4>
                    <v-textarea
                        name="input-comment"
                        label=""
                        outlined
                        v-model="deltaCommentMetadata.json_data.text"
                        rows="6"
                        aria-labelledby="label-input-comment"
                    />
                </v-card-text>
                <v-card-actions class="pb-4">
                    <v-spacer />
                    <v-btn text @click="dialog = false" color="primary">Spara kommentar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

    import {mapGetters, mapMutations} from 'vuex';

    const COMMENT_METADATA_ID = 1;

    export default {
        name: "CommentMetadata",
        props: ['metadata', 'category'],
        data: () => ({
            dialog: false,
            deltaCommentMetadata: {json_data: {text: ''}}
        }),
        watch: {

            dialog(open) {

                if (open) {

                    if (!this.commentMetadata) {
                        this.createMetadata({
                            id: this.obtainNegativeSerialId(),
                            equipment_category_id: this.category.id,
                            metadata_type_id: COMMENT_METADATA_ID,
                            json_data: {text: ''}
                        })
                    }

                    this.deltaCommentMetadata = _.cloneDeep(this.commentMetadata);

                } else {

                    this.updateMetadata(this.deltaCommentMetadata);
                }

            }

        },
        computed: {
            ...mapGetters(['selectedRevisionMetadata']),
            questionHasCommentMetadata() {
                return this.metadata && this.metadata.find(o => o.id == COMMENT_METADATA_ID);
            },
            commentMetadata() {
                return this.selectedRevisionMetadata(COMMENT_METADATA_ID, this.category.id);
            },
            questionHasCommentContent() {
                return (((this.commentMetadata) || []).json_data || []).text;
            }
        },
        methods: {
            ...mapMutations([
                'createMetadata', 'updateMetadata'
            ]),
        },

    }
</script>

<style scoped>

</style>
