<template>


    <div>

        <v-dialog
            v-model="showProgressDialog"
            hide-overlay
            persistent
            width="300"
        >
            <v-card
                color="secondary"
                dark
            >
                <v-card-title>Laddar</v-card-title>
                <v-card-text>
                    <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>


        <v-dialog v-model="showErrorDialog" max-width="300px">
            <v-card light>
                <v-card-title>Ett fel inträffade</v-card-title>
                <v-card-text>
                    {{ serverMessage }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn @click="closeClick" text color="primary">
                        Stäng
                    </v-btn>
                    <v-btn v-if="tryagain" @click="tryAgainClick" text color="primary">
                        Försök igen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>


</template>

<script>
    export default {
        name: "LoadOrErrorDialog",
        props: {loading: Boolean, serverMessage: null, tryagain: Boolean, serverError: Boolean},
        data() {
            return {};
        },

        computed: {

            showProgressDialog() {
                return this.loading && !this.serverError;
            },
            showErrorDialog() {
                return this.serverError;
            },

        },

        watch: {
            serverError() {
                window.scrollTo(0, 0);

            }
        },

        methods: {
            closeClick(){
                this.$emit('clear:error')
            },
            tryAgainClick(){
                this.$emit('tryagain');
            }
        }

    }
</script>

<style scoped>

</style>
