<template>
    <v-card elevation="1">
        <v-card-title class="pb-2">
            <v-icon left color="accent" class="mr-4">
                mdi-cloud-off-outline
            </v-icon>
            <h2 class="title">Offline</h2>
        </v-card-title>
        <v-card-text>
            Du använder Lureg i offline-läge.
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: "OfflineIndicator"
    }
</script>

<style scoped>

</style>
