<template>
    <v-card elevation="1" tile max-height="300">
        <v-img
            :src="img"
            width="100%"
            max-height="150"
            aspect-ratio="1"
            @click="onPickCameraImage"
            v-bind:class="!img ? 'bb-1 border-color-grey900' : ''"
            class="image-picker"
        >
            <div v-if="!img" class="d-flex h-100 align-center justify-center">
                <v-icon class="d-block" large>
                    mdi-camera-plus
                </v-icon>
            </div>
        </v-img>
        <v-card-text>
            <v-select
                v-model="filecat"
                :items="items"
                item-text="name"
                item-value="id"
                @change="$emit('filecategorychanged', {id, filecat})"
                label="Dokumenttyp"
                dense
                class="mt-3"
            />
        </v-card-text>

        <v-card-actions>
            <v-btn text small color="primary" @click="removeFile" v-if="!hideDeleteButton">
                Ta bort
            </v-btn>
            <input
                type="file"
                ref="deviceImage"
                name="deviceImage"
                accept="image/*"
                @change="onImagePicked"
            >
            <input
                type="file"
                ref="cameraImage"
                name="cameraImage"
                accept="image/*"
                capture="environment"
                @change="onImagePicked"
            >
        </v-card-actions>
    </v-card>


</template>

<script>
    export default {
        name: "Document",
        props: ['id', 'image', 'filecategory', 'hideDeleteButton'],

        data() {
            return {
                imageDataUrl: null,
                filecat: this.filecategory,
            }
        },

        computed: {
            items() {
                return this.$store.state.form.file_categories;
            },
            img() {
                return this.imageDataUrl || this.image || "";
            }
        },

        watch: {

            image(){
                this.imageDataUrl = null;
            }

        },

        methods: {
            onPickDeviceImage() {
                this.$refs.deviceImage.click();
            },
            onPickCameraImage() {
                this.$refs.cameraImage.click();
            },
            onImagePicked(event) {

                const files = event.target.files || event.dataTransfer.files;

                if (files && files[0]) {

                    let filename = files[0].name;

                    const fileReader = new FileReader();

                    fileReader.onload = (e) => {

                        this.imageDataUrl = fileReader.result;
                        let noImage = document.createElement("img");

                        noImage.onload = () => {
                            let dataUrl = scaleImagePragmatic(noImage);
                            this.imageDataUrl = dataUrl;
                            this.$emit('input', this.id, dataUrl);
                        };

                        noImage.src = fileReader.result;
                    };

                    fileReader.readAsDataURL(files[0]);

                }
            },

            removeFile() {
                this.imageDataUrl = '';
                this.$emit('delete', this.id);
            },
        }
    }


    function scaleImagePragmatic(img) {

        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        let width = img.width;
        let height = img.height;

        if (width > height) {
            if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
            }
        } else {
            if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
            }
        }
        canvas.width = width;
        canvas.height = height;
        ctx = canvas.getContext("2d");

        ctx.mozImageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = "high";
        ctx.webkitImageSmoothingEnabled = true;
        ctx.msImageSmoothingEnabled = true;
        ctx.imageSmoothingEnabled = true;

        ctx.drawImage(img, 0, 0, width, height);

        return canvas.toDataURL('image/jpeg', IMAGE_QUALITY);

    }

    const MAX_WIDTH = 800;
    const MAX_HEIGHT = 600;
    const IMAGE_QUALITY = 0.9;

    /** @deprecated kraschar pga minne? */
    function scaleImage(img) {
        let canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        canvas.getContext('2d').drawImage(img, 0, 0, canvas.width, canvas.height);

        while (canvas.width >= (2 * MAX_WIDTH)) {
            canvas = getHalfScaleCanvas(canvas);
        }

        if (canvas.width > MAX_WIDTH) {
            canvas = scaleCanvas(canvas, img);
        }

        return canvas.toDataURL('image/jpeg', IMAGE_QUALITY);

    }


    function getHalfScaleCanvas(canvas) {
        let halfCanvas = document.createElement('canvas');
        halfCanvas.width = canvas.width / 2;
        halfCanvas.height = canvas.height / 2;

        halfCanvas.getContext('2d').drawImage(canvas, 0, 0, halfCanvas.width, halfCanvas.height);

        return halfCanvas;
    }


    function scaleCanvas(canvas, img) {
        let scaledCanvas = document.createElement('canvas');

        let scale = MAX_WIDTH / canvas.width;

        scaledCanvas.width = canvas.width * scale;
        scaledCanvas.height = canvas.height * scale;

        let ctx = scaledCanvas.getContext("2d");
        ctx.mozImageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = "high";
        ctx.webkitImageSmoothingEnabled = true;
        ctx.msImageSmoothingEnabled = true;
        ctx.imageSmoothingEnabled = true;
        ctx.drawImage(img, 0, 0, scaledCanvas.width, scaledCanvas.height);

        return scaledCanvas;
    }

    /** @deprecated använder för mycket minne så att appen kraschar */
    function applyBilinearInterpolation(srcCanvasData, destCanvasData, scale) {
        function inner(f00, f10, f01, f11, x, y) {
            let un_x = 1.0 - x;
            let un_y = 1.0 - y;
            return (f00 * un_x * un_y + f10 * x * un_y + f01 * un_x * y + f11 * x * y);
        }
        let i, j;
        let iyv, iy0, iy1, ixv, ix0, ix1;
        let idxD, idxS00, idxS10, idxS01, idxS11;
        let dx, dy;
        let r, g, b, a;
        for (i = 0; i < destCanvasData.height; ++i) {
            iyv = i / scale;
            iy0 = Math.floor(iyv);
            // Math.ceil can go over bounds
            iy1 = (Math.ceil(iyv) > (srcCanvasData.height - 1) ? (srcCanvasData.height - 1) : Math.ceil(iyv));
            for (j = 0; j < destCanvasData.width; ++j) {
                ixv = j / scale;
                ix0 = Math.floor(ixv);
                // Math.ceil can go over bounds
                ix1 = (Math.ceil(ixv) > (srcCanvasData.width - 1) ? (srcCanvasData.width - 1) : Math.ceil(ixv));
                idxD = (j + destCanvasData.width * i) * 4;
                // matrix to vector indices
                idxS00 = (ix0 + srcCanvasData.width * iy0) * 4;
                idxS10 = (ix1 + srcCanvasData.width * iy0) * 4;
                idxS01 = (ix0 + srcCanvasData.width * iy1) * 4;
                idxS11 = (ix1 + srcCanvasData.width * iy1) * 4;
                // overall coordinates to unit square
                dx = ixv - ix0;
                dy = iyv - iy0;
                // I let the r, g, b, a on purpose for debugging
                r = inner(srcCanvasData.data[idxS00], srcCanvasData.data[idxS10], srcCanvasData.data[idxS01], srcCanvasData.data[idxS11], dx, dy);
                destCanvasData.data[idxD] = r;

                g = inner(srcCanvasData.data[idxS00 + 1], srcCanvasData.data[idxS10 + 1], srcCanvasData.data[idxS01 + 1], srcCanvasData.data[idxS11 + 1], dx, dy);
                destCanvasData.data[idxD + 1] = g;

                b = inner(srcCanvasData.data[idxS00 + 2], srcCanvasData.data[idxS10 + 2], srcCanvasData.data[idxS01 + 2], srcCanvasData.data[idxS11 + 2], dx, dy);
                destCanvasData.data[idxD + 2] = b;

                a = inner(srcCanvasData.data[idxS00 + 3], srcCanvasData.data[idxS10 + 3], srcCanvasData.data[idxS01 + 3], srcCanvasData.data[idxS11 + 3], dx, dy);
                destCanvasData.data[idxD + 3] = a;
            }
        }
    }
</script>


<style scoped>

    input[type=file] {
        position: absolute;
        left: -99999px;
    }

</style>
