<template>
    <div>
        <v-row justify="center">
            <v-col cols="12" sm="5">
                  <v-card class="px-2 py-1" elevation="2">
                    <v-card-text class="pb-1">
                        <h2 class="caption uppercase">Lureg</h2>
                        <h3 class="title mb-6">Logga in</h3>
                         <v-form ref="form">
                            <v-text-field
                                v-model="username"
                                :disabled="checkingPassword || success"
                                label="Användarnamn"
                                outlined
                                @keypress.enter="checkPassword"
                            ></v-text-field>

                            <v-text-field
                                v-model="password"
                                password
                                label="Lösenord"
                                :disabled="checkingPassword || success"
                                :append-icon="showpass ? 'visibility' : 'visibility_off'"
                                :type="showpass ? 'text' : 'password'"
                                @click:append="showpass = !showpass"
                                @keypress.enter="checkPassword"
                                outlined
                            >
                            </v-text-field>
                        </v-form>
                        <v-alert class="my-3"
                             :value="fail"
                             color="error lighten-1"
                             dark
                             dense
                             transition="scale-transition"
                        >
                            {{ failMessage }}
                        </v-alert>
                    </v-card-text>
                    <v-card-actions class="pb-4">
                        <v-row justify="center">
                            <v-col cols="12" sm="6">
                                <v-btn
                                    :loading="checkingPassword"
                                    :disabled="checkingPassword || success"
                                    color="primary"
                                    @click="checkPassword"
                                    type="submit"
                                    block
                                    large
                                >
                                    Logga in
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                  </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>

    import {Base64} from 'js-base64';

    export default {
        name: "Login",
        data: function () {
            return {
                username: null,
                password: null,
                showpass: false,
                checkingPassword: false,
                success: false,
                fail: false,
                failMessage: '',
            }
        },

        mounted() {

            let token = localStorage.getItem("token");

            if (token) {
                this.username = Base64.decode(token).split(":")[0];
                this.password = Base64.decode(token).split(":")[1];
            }

        },

        methods: {

            checkPassword() {

                let token = Base64.encode(`${this.username}:${this.password}`).toString();

                this.checkingPassword = true;
                this.fail = false;

                axios.post('/api/checkpassword', {}, {headers: {'Authorization': `Basic ${token}`},})
                    .then(() => {
                        localStorage.setItem("token", token);
                        axios.defaults.headers.common['Authorization'] = `Basic ${token}`;
                        this.success = true;

                        this.$router.replace({name: 'overview'});
                        location.reload();

                    })
                    .catch(({response}) => {
                        this.failMessage = `Fel användarnamn eller lösenord (${response && response.statusText}).`;
                        this.fail = true;
                    })
                    .then(() => this.checkingPassword = false);

            }

        }
    }
</script>

<style scoped>

</style>
