<template>
    <v-list-item class="pl-0 pr-0">
        <v-list-item-content>
            <v-list-item-title>{{ ftg.delta.company }}</v-list-item-title>
            <v-list-item-subtitle>{{ ftg.delta.visitort }}</v-list-item-subtitle>
            <v-list-item-subtitle class="mt-1">{{ this.getReadableDateString(ftg.delta.revision_started_at, true) }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action style="flex-direction: flex-row;">
            <v-btn outlined small color="primary" @click="resumeRevision" class="mr-2">
                Fortsätt
            </v-btn>
            <ConfirmDialog
                @accept="deleteRevision(ftg.original.id)"
                title="Ta bort revisionen?"
                message="Alla ändringar kommer raderas permanent."
                no-message="Avbryt"
                yes-message="Ta bort"
                hasOutlinedBtn="true"
            >
                Ta bort
            </ConfirmDialog>
        </v-list-item-action>
    </v-list-item>
</template>

<script>

    import {mapMutations, mapState} from 'vuex'
    import ConfirmDialog from "../../misc/ConfirmDialog";

    export default {
        name: "OpenedRevisionCard",
        components: {ConfirmDialog},
        props: ['ftg'],
        computed: {
            ...mapState(['selectedAreaId'])
        },
        methods: {
            ...mapMutations(['deleteRevision']),
            resumeRevision() {
                const companyArea = this.ftg.original.area_id;

                // det kan fortfarande finnas rester kvar av en "miss" som gör att en pågående revision är beroende av
                // state från det län som för närvarande är valt. Då måste det vara samma som företaget tillhör
                //
                // om inte företaget tillhör det län som är valt när man vill återuppta revisionen så skicka användaren
                // till länet så att det laddas in först och främst. sedan får man leta sig in i revisionen igen därifrån
                // fixa detta bättre senare. kanske med route guards eller liknande
                if (Number(this.selectedAreaId) === Number(companyArea)) {
                    this.$router.replace({name: 'revisionpage', params: {companyid: this.ftg.original.id, step: 0}});
                } else {
                    this.$router.replace({name: 'area', params: {areaid: companyArea}});
                    console.log("skickar avsiktligen till länsöversikten")
                }
            }
        }
    }
</script>

<style scoped>

</style>
