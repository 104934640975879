<template>

    <v-dialog v-model="dialog" max-width="800px">
        <v-form ref="contactForm" v-model="isFormValid">
            <v-card>
                <v-card-title>
                    <h2>Ändra kontaktperson</h2>
                </v-card-title>
                <v-card-text>
                    <div class="mt-4">
                        <v-text-field
                            label="Namn"
                            v-model.lazy="name"
                            type="text"
                            outlined
                            dense
                        ></v-text-field>
                        <v-text-field
                            label="Telefonnummer"
                            v-model.lazy="tele"
                            type="tel"
                            outlined
                            dense
                            :rules="[rules.phone]"
                            @input="tele = formatPhoneNumber(tele)"
                        ></v-text-field>
                        <v-text-field
                            v-model.lazy="email"
                            label="E-postadress"
                            type="email"
                            dense
                            :rules="[rules.email]"
                            outlined
                        ></v-text-field>
                    </div>

                    <h3 class="uppercase subtitle-2 font-weight-medium">Ansvarar för</h3>
                    <div v-for="atype in displayTypes" :key="atype.id">
                        <v-checkbox
                            :label="atype.short_name"
                            v-model="types"
                            :value="atype.id"
                            hide-details
                            class="mt-2"
                        >
                        </v-checkbox>
                        <v-row>
                            <v-col cols="12" md="6" class="mb-0">
                                <v-select
                                    v-model="typeResponsibilities[atype.id]"
                                    :items="$lureg.contactResponsibilities"
                                    @change="syncContactRespnsibilities(atype.id)"
                                    :disabled="!types.includes(atype.id)"
                                    label="Ansvarsområden"
                                    multiple
                                    class="mt-3 mb-0"
                                    v-show="types.includes(atype.id)"
                                    outlined
                                    dense
                                ></v-select>
                            </v-col>
                        </v-row>
                    </div>
                    <v-divider class="mt-4"></v-divider>
                    <h3 class="subtitle-1 font-medium mb-2 mt-6">Ta bort kontaktperson</h3>
                    <v-btn color="error" small class="mt-1" outlined @click="deleteContact">
                        Ta bort kontakt
                    </v-btn>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false">Spara</v-btn>
                </v-card-actions>
            </v-card>
         </v-form>
    </v-dialog>

</template>

<script>

    import {mapGetters, mapActions, mapMutations} from 'vuex';


    export default {
        name: "EditContactDialog",

        props: {
            contactId: {
                required: true,
            },
            defaultTypes: {
                type: Array,
                required: true,
            }
        },

        data: () => ({
            typeResponsibilities: {},
            isFormValid: null,
            rules: {
                required: value => !!value || 'Fältet är obligatoriskt.',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return (pattern.test(value) || !value) || 'Felaktigt utformad e-post.'
                },
                phone: value => {
                    const pattern = /^(0\d+-?\d*)$/g;
                    return (pattern.test(value) || !value) || 'Telefonnumret måste börja med 0 och endast innehålla siffror.';
                }
            }
        }),

        mounted() {
            if (!_.isUndefined(this.$refs.contactForm)) {
                this.$refs.contactForm.validate();
            }
        },

        watch: {


            contact() {

                let obj = {};

                let types = this.contact.types || [];

                types.forEach(t => {

                    obj[t.id] = Object.keys(t.pivot).filter(key => t.pivot[key] == 1);

                });

                this.typeResponsibilities = obj;

            },

        },

        computed: {

            ...mapGetters(['selectedRevisionContactById', 'activeTypesForArea', 'selectedRevision']),

            contact() {
                return this.contactId ?
                    this.selectedRevisionContactById(this.contactId) : {};
            },

            displayTypes() {
                return this.activeTypesForArea(this.selectedRevision.area_id).filter(o => this.defaultTypes.includes(o.id))
            },

            dialog: {
                get() {
                    return !!this.contactId;
                },
                set(value) {
                    this.$emit('update:contactId', value);
                },
            },

            types: {

                get() {
                    return this.contactId ?
                        this.selectedRevisionContactById(this.contactId).types.map(o => parseInt(o.id, 10)) : [];
                },
                set(types) {
                    this.syncContactTypes(types);
                }
            },

            name: {
                get() {
                    return this.contactId ?
                        this.selectedRevisionContactById(this.contactId).name
                        : "";
                },
                set(value) {
                    let update = {id: this.contactId, name: value};
                    this.$store.commit('updateContact', update)
                },
            },
            tele: {
                get() {
                    return this.contactId ?
                        this.selectedRevisionContactById(this.contactId).tele
                        : "";
                },
                set(value) {
                    let update = {id: this.contactId, tele: value};
                    this.$store.commit('updateContact', update)

                },
            },
            email: {
                get() {
                    return this.contactId ?
                        this.selectedRevisionContactById(this.contactId).email
                        : "";
                },
                set(value) {
                    let update = {id: this.contactId, email: value};
                    this.$store.commit('updateContact', update)

                },
            },

        },
        methods: {
            ...mapMutations(["updateContact"]),
            ...mapActions([]),

            syncContactTypes(types) {

                console.log("sync types", types);

                let contact = _.cloneDeep(this.selectedRevisionContactById(this.contactId));

                contact.types = contact.types.filter(o => types.includes(o.id));

                types.forEach(v => {
                    if (!contact.types.some(o => o.id == v)) {
                        this.addNewTypesToContact([v], contact);
                    }
                });

                this.$store.commit('updateContact', {id: contact.id, types: contact.types});

            },

            syncContactRespnsibilities(type) {

                let contact = _.cloneDeep(this.selectedRevisionContactById(this.contactId));

                if (!contact.types.some(o => o.id == type)) {
                    console.log("no type for " + type);
                    return;
                }

                let responsibilities = this.typeResponsibilities[type];

                let typePivots = contact.types.find(o => o.id == type).pivot;

                this.$lureg.contactResponsibilities.map(o => o.value)
                    .forEach(function (item) {
                        typePivots[item] = responsibilities.includes(item) ? 1 : 0;
                    });

                this.$store.commit('updateContact', {id: contact.id, types: contact.types});

            },

            deleteContact() {

                let update = {id: this.contactId, state: 'deleted'};

                console.log("delete contact", update);

                this.$store.commit('updateContact', update);

                this.dialog = false;

            }
        }
    }
</script>

<style scoped>


</style>
