<template>

    <AssignResponsibilityDialog v-if="visible" responsibility="quality_manager"
                                :defaultTypes="sharedtypes"/>


</template>

<script>
    import {mapGetters} from 'vuex';
    import AssignResponsibilityDialog from "../Components/AssignResponsibilityDialog";
    export default {
        name: "QualityManagerMetadata",
        components: {AssignResponsibilityDialog},
        props: ['metadata', 'sharedtypes'],

        computed: {
            ...mapGetters(['selectedRevision']),
            visible() {

                const METADATA_ID = 5;

                return this.metadata && this.metadata.some(o => o.id == METADATA_ID);
            },
        }
    }
</script>

<style scoped>

</style>
