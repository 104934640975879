<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-container>
            <v-row>
                <v-col cols="12" md="6">
                    <v-card elevation="2">
                        <v-card-text>
                            <h3 class="caption uppercase">{{ selectedRevision.company }}</h3>
                            <h4 class="title mb-2">Sammanfattning</h4>

                            <v-expansion-panels flat tile class="summary-navigation sidebar-nav">
                                <v-expansion-panel v-for="question in $store.state.form.envquestions" :key="question.env_question_id" class="pb-4 pt-2 mb-3">
                                    <v-expansion-panel-header class="py-2 px-0" :disable-icon-rotate="true" expand-icon="">
                                        <v-row no-gutters>
                                            <v-col cols="12">
                                                <div class="text-xs mb-2 uppercase text-grey500">Miljö</div>
                                                <router-link
                                                    :to="{name: 'revisionpage', params: {companyid: $route.params.companyid, step: 4 }}"
                                                    :ripple="false"
                                                    class="font-weight-medium text-expansion-panel"
                                                    style="line-height: 125%;"
                                                >
                                                    {{ question.env_question_title }}
                                                </router-link>
                                                <div class="mt-2 text-expansion-panel" style="line-height: 125%;">
                                                    <div v-for="answer in question.answers" :key="answer.env_answer_id">
                                                        <template v-if="envAnswerIsChecked(answer.env_answer_id)">
                                                            {{ answer.env_answer_title }}
                                                        </template>
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <template v-slot:actions v-if="envQuestionShouldShowWarning(question)">
                                            <v-icon color="error" title="Svaret uppfyller inte kraven">mdi-alert-circle-outline</v-icon>
                                        </template>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content class="caption text-grey500" v-if="envQuestionShouldShowWarning(question)">
                                        Svaret uppfyller inte kraven.
                                    </v-expansion-panel-content>
                                </v-expansion-panel>

                                <v-expansion-panel
                                    v-for="cat in all"
                                    :key="cat.id"
                                    class="pb-4 pt-2 mb-3"
                                >
                                    <v-expansion-panel-header class="py-2 px-0"
                                                              :disable-icon-rotate="cat.hasNoEquipment">
                                        <v-row no-gutters>
                                            <v-col cols="12">
                                                <div class="text-xs mb-2 uppercase text-grey500">
                                                    <span v-for="type in cat.types" :key="type.id" class="mr-2">
                                                        {{ type.short_name }}
                                                    </span>
                                                </div>
                                                <router-link
                                                    :to="{name: 'revisionpage', params: {companyid: $route.params.companyid, step: cat.step }}"
                                                    :ripple="false" class="font-weight-medium"
                                                    style="line-height: 125%;">
                                                    {{ cat.title }}
                                                </router-link>
                                                <div class="mt-2" v-if="cat.equipments.length"
                                                     style="line-height: 140%;">
                                                    {{ cat.equipments.map(e => e.name).join(', ') }}
                                                </div>
                                                <div class="mt-2" v-if="cat.metadata_types.some((o) => o.id === 11)"
                                                     style="line-height: 140%;">
                                                    {{ numberMetadata(cat.id) }}
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <template v-slot:actions>
                                            <template v-if="cat.hasNoEquipment">
                                                <v-icon color="error">mdi-alert-circle-outline</v-icon>
                                            </template>
                                            <template v-else>
                                                <v-icon>mdi-chevron-down</v-icon>
                                            </template>
                                        </template>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content class="caption text-grey500">
                                        <template v-if="cat.ruleSet.length">
                                            <div v-for="(rule, index) in cat.ruleSet" :key="index">
                                                {{ rule }}
                                            </div>
                                        </template>
                                        <template v-else>
                                            Informationshämtning
                                        </template>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="5" offset-md="1">
                    <v-card elevation="2">
                        <v-card-text>
                            <h3 class="caption uppercase">{{ selectedRevision.company }}</h3>
                            <h4 class="title mb-6">Klassificering</h4>
                            <div v-for="asp in aspire" :key="asp.id">
                                <v-select
                                    v-model="selectedAspire[asp[0].revision_type]"
                                    :items="asp"
                                    item-text="value"
                                    item-value="id"
                                    @change="changeAspire"
                                    :label="$store.state.form.types.find(t => t.id == asp[0].revision_type).short_name"
                                    outlined
                                    class="mb-0"
                                />
                            </div>

                            <h5 class="subtitle-2 mb-3">Har företaget fått respit?</h5>
                            <v-menu
                                v-model="respitePicker"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="respite_date"
                                        label="Respitdatum"
                                        readonly
                                        clearable
                                        v-on="on"
                                        outlined
                                    />
                                </template>
                                <v-date-picker v-model="respite_date" @input="respitePicker = false"
                                               :first-day-of-week="1" header-color="secondary" locale="sv-se"/>
                            </v-menu>

                            <v-textarea
                                name="input-comment"
                                label="Noteringar"
                                outlined
                                v-model="revision_notes"
                                rows="3"
                                value=""
                                class="mt-1"
                            />

                            <div v-if="selectedSignatureContact" class="mb-10">
                                <header class="mb-2 d-flex justify-space-between align-center">
                                    <div class="mb-0">
                                        <h5 class="subtitle-2 mb-0">Signatur</h5>
                                        <p class="mb-0">Signerad av {{ selectedSignatureContact.name }}</p>
                                    </div>
                                    <v-btn outlined small :color="'primary'"
                                           @click="removeSignature(selectedSignatureContact.id)">
                                        Rensa
                                    </v-btn>
                                </header>
                                <v-img
                                    :src="selectedSignatureContact.signature"
                                    max-height="100"
                                    contain
                                />
                            </div>

                            <v-dialog v-if="!hasSignature" v-model="signatureDialog" fullscreen hide-overlay
                                      transition="dialog-bottom-transition">
                                <template v-slot:activator="{ on }">
                                    <v-btn color="primary" outlined small v-on="on" class="mb-10"
                                           v-text="'Lägg till signatur'"/>
                                </template>
                                <v-card tile>
                                    <v-toolbar color="secondary" dark>
                                        <v-btn icon @click="signatureDialog = false">
                                            <v-icon>close</v-icon>
                                        </v-btn>
                                        <v-toolbar-title>Signera revision</v-toolbar-title>
                                    </v-toolbar>
                                    <v-card-text>
                                        <div class="mt-5" fill-height>
                                            <VueSignaturePad
                                                class="b-1 border-color-grey800"
                                                width="100%"
                                                height="400px"
                                                ref="signaturePad"
                                                :options="{backgroundColor: 'rgba(255,255,255,255)'}"
                                            />
                                            <div class="d-flex justify-space-between mt-4">
                                                <div class="mt-2">
                                                    Jag godkänner <a target="_top"
                                                                     href="https://www.autoteknik.info/integritetspolicy">hantering
                                                    av personuppgifter</a> för samtliga kontaktpersoner jag angett.
                                                </div>
                                                <v-spacer/>
                                                <v-select
                                                    v-model="signatureContact"
                                                    :items="selectedRevisionContacts"
                                                    item-text="name"
                                                    item-value="id"
                                                    label="Signeras av"
                                                    outlined
                                                    dense
                                                />
                                            </div>
                                            <div class="d-flex justify-end mt-4">
                                                <v-btn text color="primary" @click="clearSignature" class="mr-2">Rensa
                                                </v-btn>
                                                <v-btn text color="primary" @click="saveSignature">Spara signatur
                                                </v-btn>
                                            </div>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-dialog>
                            <v-divider/>
                        </v-card-text>
                        <v-card-actions class="pb-8 pt-6 ml-2">
                            <v-row justify="center">
                                <v-col cols="12" lg="8">
                                    <ConfirmDialog
                                        @accept="finish()"
                                        title="Avsluta revisionen?"
                                        yes-message="Avsluta"
                                        no-message="Avbryt"
                                        message="Du kan inte göra några ändringar efter avslutad revision."
                                        class="finish-revision-btn"

                                    >
                                        Avsluta revisionen
                                    </ConfirmDialog>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog width="500" v-model="errorModal">
            <v-card>
                <v-card-title>
                    Revisionen kunde inte avslutas
                </v-card-title>
                <v-card-text class="mt-3">
                    <v-alert text type="error" v-html="errorMessage"/>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        color="primary"
                        text
                        @click="errorModal = false"
                    >
                        Stäng
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>

</template>

<script>
import {mapGetters, mapMutations, mapState} from 'vuex';
import ConfirmDialog from "../../misc/ConfirmDialog";

export default {
    name: "SummaryForm",
    components: {ConfirmDialog},
    props: ['form'],
    data: () => ({
        selectedAspire: {},
        respitePicker: false,
        signatureDialog: false,
        signatureContact: null,
        errorModal: false,
        errorMessage: null,
    }),
    computed: {

        hasSelectedAspire() {
            return !Object.values(this.selectedRevision.aspire).some(v => v === null);
        },
        hasSignature() {
            return this.selectedRevisionContacts.some(c => c.signature);
        },
        selectedSignatureContact() {
            return this.selectedRevisionContacts.find(c => c.signature);
        },
        atLeastOneClassIsTemporary() {
            return this.$store.state.form.classes
                .filter(cls => Object.values(this.selectedAspire).includes(cls.id))
                .some(cls => cls.class_temp === 1);
        },
        aspire() {

            return this.selectedRevision.types.map(t => {

                return this.$store.state.form.classes
                    .filter(cls => cls.revision_type == t.id);

            })
        },

        all() {

            const equipments = this.selectedRevision.equipments.map(o => o.bid);

            return _.cloneDeep(this.selectedRevisionDynamicForm.categories)

                .map((cat, idx) => {

                    cat.step = idx + 5;

                    cat.equipments =
                        cat.equipments.filter(e => equipments.includes(e.bid));

                    cat.hasNoEquipment =
                        cat.equipments.length === 0 && cat.rules.some(rule => rule.mandatory > 0);

                    return cat;

                });


        },
        ...mapState(['selectedRevisionId']),
        ...mapGetters(['selectedRevisionMetadata', 'selectedRevisionContacts', 'selectedRevisionDynamicForm', 'selectedRevision', 'selectedRevisionEnvReportAnswers']),

        revision_notes: {
            get() {
                return this.selectedRevision.revision_notes || "";
            },
            set(value) {
                this.$store.commit('setCompanyField', {revision_notes: value})
            },
        },
        respite_date: {
            get() {
                return this.selectedRevision.respite_date || "";
            },
            set(value) {
                this.setCompanyField({respite_date: value});
            },
        },
    },

    created() {


        let preSelectedSigner =
            this.selectedRevisionContacts.find(c => c.types.some(t => t.pivot.audit_manager))
            || this.selectedRevisionContacts.find(c => true);

        if (preSelectedSigner) {
            this.signatureContact = preSelectedSigner.id;
        }

        this.selectedAspire = _.cloneDeep(this.selectedRevision.aspire);

    },

    watch: {

        signatureDialog() {
            //hack för att signaturen ska fungera i dialogruta.
            this.$nextTick(function () {
                if (this.$refs.signaturePad) this.$refs.signaturePad.resizeCanvas();
            });

            // testa att resize canvas även efter en timeout eftersom canvasen fortfarande verkar ha problem ibland
            setTimeout(() => {
                if (this.$refs.signaturePad) this.$refs.signaturePad.resizeCanvas();
            }, 1000);
        }
    },

    methods: {
        ...mapMutations(['updateContact', 'setRevisionAspire', 'finishRevision', 'setCompanyField']),
        numberMetadata(category) {
            return this.selectedRevisionMetadata(11, category)?.json_data?.number ?? null;
        },
        finish() {

            this.errorMessage = "";


            if (!this.hasSelectedAspire) {
                this.errorMessage += "<li>Klassificering måste väljas.</li>";
            }

            if (!this.hasSignature) {
                this.errorMessage += "<li>En signatur måste anges.</li>";
            }

            if (this.atLeastOneClassIsTemporary && !this.respite_date) {
                this.errorMessage += "<li>Respitdatum måste anges om en revisionstyp är temporärt godkänd.</li> ";
            }

            if (this.selectedRevision.types.length === 0) {
                this.errorMessage += "<li>Företaget saknar revisionstyper.</li>";
            }

            if (this.selectedRevisionContacts.length === 0) {
                this.errorMessage += "<li>Företaget saknar kontaktperson.</li> ";
            }

            if (this.errorMessage) {
                this.errorMessage = `<ul class="list-unstyled mt-3">${this.errorMessage}</ul>`;
                this.errorModal = true;
                return;
            }

            this.finishRevision(this.selectedRevisionId);
            this.$store.commit('setCompanyField', {revision_ended_at: ~~(Date.now() / 1000)});
            this.$router.replace({name: 'overview'});


        },

        changeAspire() {
            this.setRevisionAspire(_.cloneDeep(this.selectedAspire));
        },

        clearSignature() {
            this.$refs.signaturePad.clearSignature();
        },
        saveSignature() {

            const {isEmpty, data} = this.$refs.signaturePad.saveSignature();

            this.updateContact({id: this.signatureContact, signature: data});

            this.signatureDialog = false
        },
        removeSignature(id) {
            this.updateContact({id, signature: null});
        },

        envQuestionShouldShowWarning(question){

            let answeredQuestions = question.answers.filter(o => this.selectedRevisionEnvReportAnswers.includes(o.env_answer_id))
            let hasZeroAnswers = answeredQuestions.length === 0

            if (question.env_question_id === 3){
                // ingen varning om det finns nekande svar på denna fråga, bara när den är obesvarad
                return hasZeroAnswers
            } else {
                 return hasZeroAnswers || answeredQuestions.some(o => o.env_answer_boolean === 0)
            }

        },
        envAnswerIsChecked(answerId) {
            return this.selectedRevisionEnvReportAnswers.includes(answerId);
        },
    }

}
</script>

<style scoped>

.v-list-item__title, .v-list-item__subtitle {
    white-space: normal;
}

</style>
