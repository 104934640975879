<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-card class="px-2 py-1" elevation="2">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <h2 class="headline mb-6">Företagsuppgifter</h2>
                        <v-form ref="form" v-model="isFormValid">
                            <v-row>
                                <v-col cols="12" md="6" class="pb-0">
                                    <v-text-field
                                        v-model.trim="company"
                                        label="Företagsnamn*"
                                        placeholder="Ange företagsnamn"
                                        type="text"
                                        :rules="[rules.required]"
                                        outlined
                                    />
                                </v-col>
                                <v-col cols="12" md="6" class="pb-0">
                                    <v-text-field
                                        v-model.trim="autoshop_code"
                                        label="Verkstadskod"
                                        type="text"
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6" class="pb-0">
                                    <v-text-field
                                        v-model.trim="org_nr"
                                        label="Organisationsnummer"
                                        v-mask="'######-####'"
                                        masked="true"
                                        type="text"
                                        outlined
                                    />
                                </v-col>
                                <v-col cols="12" md="6" class="pb-0">
                                    <v-text-field
                                        v-model="positioningStatusMessage"
                                        label="Hämta position"
                                        readonly
                                        @click="updatePosition"
                                        :hint="positioningStatusMessage2"
                                        persistent-hint
                                        outlined
                                    >
                                        <v-fade-transition slot="append">
                                            <v-progress-circular
                                                v-if="retreivingPosition"
                                                size="24"
                                                color="info"
                                                indeterminate
                                            />
                                            <v-icon v-else @click="updatePosition">mdi-crosshairs-gps</v-icon>
                                        </v-fade-transition>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6" class="pb-0">
                                    <v-text-field
                                        v-model.trim="employees"
                                        label="Antal anställda (verkstadspersonal)*"
                                        type="number"
                                        outlined
                                        min="0"
                                        :rules="[rules.required]"
                                    >
                                        <span class="mt-1 text-grey300" slot="append">st</span>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="6" class="pb-0">
                                    <v-text-field
                                        v-model.trim="website"
                                        label="Webbplats"
                                        type="url"
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6" class="pb-0">
                                    <v-text-field
                                        v-model.trim="contact_email"
                                        label="E-post"
                                        type="email"
                                        :rules="[rules.email]"
                                        outlined
                                    />
                                </v-col>
                                <v-col cols="12" md="6" class="pb-0">
                                    <v-text-field
                                        @keydown.native.space.prevent
                                        v-model.trim="phone"
                                        label="Telefon"
                                        type="tel"
                                        :rules="[rules.phone]"
                                        @input="phone = formatPhoneNumber(phone)"
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <label for="input-notes">Övriga noteringar</label>
                                    <v-textarea
                                        name="input-comment"
                                        v-model="other_notes"
                                        value=""
                                        rows="3"
                                        id="input-notes"
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>

<script>

    import {mapGetters, mapState, mapMutations} from 'vuex'
    import { mask } from 'vue-the-mask';
    export default {
        components: {},
        directives: {
            mask
        },
        data: () => ({
            google: null,
            notesDialog: false,
            retreivingPosition: false,
            isFormValid: null,
            positioningStatusMessage: null,
            positioningStatusMessage2: null,
            rules: {
                required: value => !!value || 'Fältet är obligatoriskt.',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return (pattern.test(value) || !value) || 'Felaktigt utformad e-post.';
                },
                phone: value => {
                    const pattern = /^(0\d+-?\d*)$/g;
                    return (pattern.test(value) || !value) || 'Telefonnumret måste börja med 0 och endast innehålla siffror.';
                }
            }
        }),
        name: "CompanyForm",
        props: ['companyid'],
        watch: {
            isFormValid(newValue){
                this.$emit('update:nextblocked', !newValue);
            }
        },
        mounted() {
            this.$refs.form.validate();
        },
        computed: {
            ...mapState(['openedRevisions']),
            ...mapGetters(['selectedRevision', 'isselectedRevision']),

            area_id: () => {
                return this.selectedRevision && this.selectedRevision.area_id;
            },

            company: {
                get() {
                    return this.selectedRevision.company;
                },
                set(value) {
                    this.$store.commit('setCompanyField', {company: value})
                },
            },

            employees: {
                get() {
                    return this.selectedRevision.employees;
                },
                set(value) {
                    this.$store.commit('setCompanyField', {employees: value})
                },
            },
            website: {
                get() {
                    return this.selectedRevision.website;
                },
                set(value) {
                    this.$store.commit('setCompanyField', {website: value})
                },
            },

            latitude: {
                get() {
                    return this.selectedRevision.latitude;
                },
                set(value) {
                    this.$store.commit('setCompanyField', {latitude: value})
                },
            },

            longitude: {
                get() {
                    return this.selectedRevision.longitude;
                },
                set(value) {
                    this.$store.commit('setCompanyField', {longitude: value})
                },
            },

            other_notes: {
                get() {
                    return this.selectedRevision.other_notes;
                },
                set(value) {
                    this.$store.commit('setCompanyField', {other_notes: value})
                },
            },

            contact_email: {
                get() {
                    return this.selectedRevision.contact_email;
                },
                set(value) {
                    this.$store.commit('setCompanyField', {contact_email: value})
                },
            },

            phone: {
                get() {
                    return this.selectedRevision.phone;
                },
                set(value) {
                    this.$store.commit('setCompanyField', {phone: value})
                },
            },

            org_nr: {
                get() {
                    return this.selectedRevision.org_nr;
                },
                set(value) {
                    this.$store.commit('setCompanyField', {org_nr: value})
                },
            },

            autoshop_code: {
                get() {
                    return this.selectedRevision.autoshop_code;
                },
                set(value) {
                    this.$store.commit('setCompanyField', {autoshop_code: value})
                },
            },
        },

        methods: {
            updatePosition() {

                this.retreivingPosition = true;
                this.positioningStatusMessage = "Söker...";

                if (navigator.geolocation) {
                    let watchId = navigator.geolocation.watchPosition(position => {

                            this.positioningStatusMessage2 = `${_.round(position.coords.latitude, 3)},${_.round(position.coords.longitude, 3)} Ø${_.round(position.coords.accuracy)}`;

                            if (position.coords.accuracy < 100) {
                                this.latitude = position.coords.latitude;
                                this.longitude = position.coords.longitude;
                                this.retreivingPosition = false;
                                this.positioningStatusMessage = "Uppdaterad";
                                navigator.geolocation.clearWatch(watchId);

                                //todo: visa adressen för den nya koordinaten?
                            }

                        },
                        (er) => console.log(er), {maximumAge: 900000, enableHighAccuracy: true});
                } else {
                    alert("positionering fungerar ej")
                }

            },
            ...mapMutations([]),
        },

    }
</script>

<style scoped>


</style>
