<template>

    <v-app>

        <v-navigation-drawer stateless v-model="drawer" app>

            <v-list>

                <v-list-item
                    @click="drawer = false"
                    :to="{name: 'overview'}"
                >
                    <v-list-item-content>
                        <v-list-item-title>
                            Översikt
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    v-for="area in areas"
                    :key="area.cid"
                    @click="drawer = false"
                    replace
                    :to="{path: '/area/' + area.cid}"
                    two-line
                >
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ area.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{ area.workshopCount }} företag <br>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

        </v-navigation-drawer>

        <v-app-bar app dense fixed dark class="primary">
            <v-btn icon @click="$router.back()" v-if="!shouldShowNavigateBackIcon">
                <v-icon v-if="$route.name == 'revisionpage'">mdi-exit-to-app</v-icon>
                <v-icon v-else>arrow_back</v-icon>
            </v-btn>
            <v-app-bar-nav-icon v-if="shouldShowNavigateBackIcon" @click.stop="drawer = !drawer"/>
            <v-toolbar-title>{{ toolbarTitle }}</v-toolbar-title>

            <v-spacer/>

            <v-menu offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>
                <v-list>
<!--                    <v-list-item @click="setShowDebug(!showDebug)">-->
<!--                        <v-list-item-subtitle>-->
<!--                            <span :class="{'font-bold': showDebug}">Aktivera felsökning</span>-->
<!--                        </v-list-item-subtitle>-->
<!--                    </v-list-item>-->
<!--                    <v-list-item @click="fetchForm()">-->
<!--                        <v-list-item-subtitle>-->
<!--                            <span>Uppdatera formulär</span>-->
<!--                        </v-list-item-subtitle>-->
<!--                    </v-list-item>-->
                        <v-list-item @click="">
                            <v-list-item-subtitle>
                                <span>Version 1.1.8 </span>
                            </v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item @click="">
                            <v-list-item-subtitle>
                                <span>{{ host }}</span>
                            </v-list-item-subtitle>
                        </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>

        <v-content>
            <div>
                <v-container class="container pb-0" v-if="!isOnline">
                    <v-row align="center">
                        <v-col class="pb-0">
                            <transition name="fade">
                                <OfflineIndicator v-if="!isOnline"/>
                            </transition>
                        </v-col>
                    </v-row>
                </v-container>

                <v-container class="container--fluid">
                    <v-row align="center">
                        <v-col>
                            <transition name="slide">
                                <router-view/>
                            </transition>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </v-content>

        <v-snackbar
            v-model="snackbar"
            bottom
            dark
            :timeout="6000"
            color="secondary"
        >
            {{ snackbarContent }}

        </v-snackbar>

        <NewVersionAvailableDialog :show="newVersionAvailable"/>
    </v-app>

</template>

<script>

    import {mapGetters, mapActions, mapMutations, mapState} from 'vuex'
    import AreaOverview from "./components/MainPage/MainPageOverview"
    import DebugHeader from "./components/misc/DebugHeader";
    import DebugDialog from "./components/misc/DebugDialog";
    import OfflineIndicator from "./components/misc/OfflineIndicator";
    import NewVersionAvailableDialog from "./components/misc/NewVersionAvailableDialog";


    export default {
        components: {NewVersionAvailableDialog, OfflineIndicator, DebugDialog, DebugHeader, AreaOverview},
        data: () => ({
            selectedArea: null,
            isOnline: false,
            host: window.location.hostname,
        }),
        computed: {
            toolbarTitle() {
                switch (this.$route.name) {
                    case 'revisionpage':
                        return this.selectedRevision.company;
                    case 'area':
                        return this.selectedAreaName ? this.selectedAreaName : '';
                    case 'overview':
                        return 'Översikt';
                    case 'booking':
                        return 'Bokning';
                    default:
                        return 'Lureg';
                }
            },
            shouldShowNavigateBackIcon() {
                return ['area', 'overview'].includes(this.$route.name);
            },
            drawer: {
                get() {
                    return this.$store.state.drawer;
                },
                set(value) {
                    this.$store.commit('drawer', value);
                }
            },
            snackbar: {
                get() {
                    return this.$store.state.snackbar;
                },
                set(value) {
                    this.$store.commit('snackbar', value);
                }
            },
            snackbarContent() {
                return this.$store.state.snackbarContent;
            },
            ...mapState(['areas', 'showDebug', 'newVersionAvailable']),
            ...mapGetters(['selectedRevision', 'openedRevisionsCount', 'selectedAreaName'])
        },
        created: function () {

            if (navigator.geolocation) {
                navigator.geolocation.watchPosition(position => this.setPosition(position),
                    (er) => console.error(er), {maximumAge: 900000, enableHighAccuracy: true});
            } else {
                alert("positionering fungerar ej")
            }

            this.fetchAreas();
            this.fetchForm();

            this.isOnline = navigator.onLine;
            window.addEventListener('online', (e) => this.isOnline = true, false);
            window.addEventListener('offline', (e) => this.isOnline = false, false);

        },

        methods: {
            ...mapActions([
                'fetchAreas', 'fetchForm',
            ]),
            ...mapMutations([
                'setPosition', 'setShowDebug'
            ]),
            commaseparatedListOfActiveTypes({activeTypes}) {
                return activeTypes.map(a => a['short_name']).join(', ')
            },
        }

    }
</script>

<style>

    html {
        /* forhindrar pull-to-refresh i chrome */
        overscroll-behavior: contain;
    }

</style>

<style scoped>

</style>
