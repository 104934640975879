<template>
    <div>
        <v-container class="elevation-0 mb-12">
            <template>
                <LoadOrErrorDialog v-if="!isOpenedRevision(companyid) && !fetchedRevisionData"
                    tryagain @tryagain="fetchData"
                    class="mt-4"
                    :loading="loading"
                    :serverMessage="serverMessage"
                    :serverError="serverError"
                    @clear:error="cancelGoBack"
                />


                <v-row justify="center" class="align-items-center" style="margin-top: 60px;" v-else-if="!isOpenedRevision(companyid)">
                    <v-col cols="12" md="6" class="text-center">
                        <div class="mb-6 d-flex justify-center">
                            <RevisionIcon></RevisionIcon>
                        </div>
                        <v-btn @click="startRevision" outlined large color="primary" class="mt-12 ml-4">
                            Börja revision
                            <v-icon right>
                                mdi-chevron-right
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <div v-else-if="step == 0"/>

                <v-row v-else-if="step > 0 && step != totalSteps">
                    <v-col cols="3" class="caption">
                        <div class="mt-0 sidebar-nav section-navigation" v-for="(section, index) in revisionSections" :key="section.step">
                            <v-list-item
                                class="pt-0 pb-0 pl-0"
                                v-on="isCurrentSectionStep(section.step) ? {} : { click: () => $router.replace({name: 'revisionpage', params: {companyid: companyid, step: section.step }})}"
                                :ripple="false"
                            >
                                <v-list-item-icon class="mr-4" style="margin-left: -8px;">
                                    <template v-if="(section.step <= 4 && step > section.step) || (section.step === 5 && step >= totalSteps - 1)">
                                        <v-icon class="font-size-19 sidebar-nav-icon">
                                            mdi-circle
                                        </v-icon>
                                    </template>
                                    <template v-else-if="isCurrentSectionStep(section.step)">
                                        <v-icon class="font-size-19 sidebar-nav-icon">
                                            mdi-circle-outline
                                        </v-icon>
                                    </template>
                                    <template v-else>
                                        <v-icon class="font-size-19 text-grey800">
                                            mdi-circle-outline
                                        </v-icon>
                                    </template>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="pl-1 pr-1">
                                        {{ section.title }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                             <v-list-item
                                class="pl-1 pb-2 pt-2"
                                :class="(section.step <= 4 && step > section.step) || (section.step === 5 && step >= totalSteps - 1) ? 'bl-2-indigo--lighten3' : 'bl-2-grey800'"
                                style="min-height: 32px;"
                                v-show="(index + 1) < revisionSections.length"
                            ></v-list-item>
                        </div>
                    </v-col>
                    <v-col cols="9">
                        <SelectTypesForm v-if="step == 1"/>
                        <CompanyForm :nextblocked.sync="nextBlocked" v-else-if="step == 2"/>
                        <AddressForm :nextblocked.sync="nextBlocked" v-else-if="step == 3"/>
                        <EnvironmentForm v-else-if="step == 4"/>
                        <QuestionForm v-else-if="step <= totalSteps - 2" :qstep="step - 4" :form="selectedRevisionDynamicForm"/>
                        <EditContactsForm v-else-if="step == totalSteps - 1"/>
                    </v-col>
                </v-row>

                <SummaryForm v-else-if="step == totalSteps"
                             :form="selectedRevisionDynamicForm"/>
            </template>


            <v-layout>
                <router-view v-if="selectedRevision" :company="selectedRevision"></router-view>
            </v-layout>


            <v-dialog
                v-model="exitConfirmDialog"
                width="400"
            >
                <v-card>
                    <v-card-title>
                        Vill du lämna revisionen?
                    </v-card-title>
                    <v-card-actions>
                        <v-spacer/>
                        <v-btn
                            color="primary"
                            text
                            @click="rejectExit"
                        >
                            Avbryt
                        </v-btn>
                        <v-btn
                            color="primary"
                            text
                            @click="confirmExit"
                        >
                            Lämna
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>

        <v-bottom-navigation
            fixed
            horizontal
            class="form-navigation align-items-center"
            v-if="isOpenedRevision(companyid)"
        >
            <v-btn @click="onPreviousClicked"
                :disabled="parseInt(step) < 2"
                text
            >
                <v-icon class="mr-2" left color="primary">mdi-chevron-left</v-icon>
                <span>Föregående</span>
            </v-btn>

            <v-btn
                @click="onNextClicked"
                v-show="parseInt(step) + 1 <= totalSteps"
            >
                <span>Nästa</span>
                <v-icon class="ml-2" right color="primary">mdi-chevron-right</v-icon>
            </v-btn>
        </v-bottom-navigation>

        <v-dialog width="500" v-model="errorModal">
            <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                    <p class="red--text font-medium">Det finns fel i formuläret som måste åtgärdas innan du kan fortsätta.</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="errorModal = false"
                    >
                        Stäng
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>


<script>

    import {mapGetters, mapState, mapMutations} from 'vuex';
    import CompanyForm from "./Subpages/CompanyForm";
    import EnvironmentForm from "./Subpages/EnvironmentForm";
    import QuestionForm from "./Subpages/QuestionForm";
    import LoadOrError from "../misc/LoadOrError";
    import DebugSheet from "../misc/DebugSheet";
    import SummaryForm from "./Subpages/SummaryForm";
    import SelectTypesForm from "./Subpages/SelectTypesForm";
    import EditContactsForm from "./Subpages/EditContactsForm";
    import LoadOrErrorDialog from "../misc/LoadOrErrorDialog";
    import AddressForm from "./Subpages/AddressForm";
    import RevisionIcon from "../misc/RevisionIcon";

    export default {
        components: {
            LoadOrErrorDialog,
            EditContactsForm,
            SelectTypesForm,
            SummaryForm,
            DebugSheet, LoadOrError,  QuestionForm, EnvironmentForm, CompanyForm, AddressForm, RevisionIcon
        },
        data: () => ({
            fetchedRevisionData: null,
            serverMessage: null,
            serverError: false,
            loading: false,
            exitConfirmDialog: false,
            exitConfirm: null,
            nextBlocked: false,
            errorModal: false,
        }),
        name: "RevisionPage",
        props: ['companyid', 'step'],
        computed: {
            totalSteps() {
                return this.selectedRevisionDynamicForm.categories_length + 6;
            },
            revisionSections() {
                if (this.totalSteps <= 6) return [];
                 return [
                    {
                        step: 1,
                        title: 'Revisionstyper',
                    },
                    {
                        step: 2,
                        title: 'Företagsuppgifter',
                    },
                    {
                        step: 3,
                        title: 'Adressuppgifter',
                    },
                    {
                        step: 4,
                        title: 'Miljö',
                    },
                    {
                        step: 5,
                        title: 'Egenskaper',
                    },
                    {
                        step: this.totalSteps - 1,
                        title: 'Kontaktpersoner',
                    },
                    {
                        step: this.totalSteps,
                        title: 'Sammanfattning'
                    }
                ];
            },
            ...mapState([]),
            ...mapGetters(['selectedRevision', 'isOpenedRevision', 'selectedRevisionDynamicForm', 'isFinishedRevision']),

        },

        created() {

            this.resumeRevision(this.companyid);

            if (this.step == 0) {
                this.$router.replace({
                    name: 'revisionpage',
                    params: {companyid: this.companyid, step: 1}
                });
            }
        },
        mounted() {
            this.fetchData();
        },

        methods: {
            ...mapMutations([
                'selectRevision', 'resumeRevision'
            ]),
            onNextClicked(){

                if (!this.nextBlocked){
                    this.$router.replace({name: 'revisionpage', params: {companyid: this.companyid, step: parseInt(this.step) + 1}})
                } else {
                    this.errorModal = true;
                }
            },
            onPreviousClicked(){
                this.nextBlocked = false;
                this.$router.replace({name: 'revisionpage', params: {companyid: this.companyid, step:  parseInt(this.step) - 1}})
            },
            rejectExit(){
                this.exitConfirm(false);
                this.exitConfirmDialog = false
            },
            confirmExit(){
                this.exitConfirm();
            },
            cancelGoBack() {
                this.serverError = false;
                this.$router.back()
            },

            startRevision() {
                this.selectRevision(this.fetchedRevisionData);
                this.$store.commit('setCompanyField', {revision_started_at: ~~(Date.now() / 1000)})
            },

            fetchData() {

                this.loading = true;

                if (!this.isOpenedRevision(this.companyid)) {
                    axios.get('/api/company/' + this.companyid)
                        .then(({data}) => {
                            this.fetchedRevisionData = data;
                            this.serverError = false;
                        })
                        .catch((err) => {
                            this.serverMessage = this.parseServerError(err);
                            this.serverError = true;

                        })
                        .then(() => {
                            this.loading = false;
                        });

                }

            },

            isCurrentSectionStep(sectionStep) {
                return this.step == sectionStep || (sectionStep === 5 && this.step >= 5 && this.step < this.totalSteps - 1);
            },

        },

        beforeRouteLeave(to, from, next) {

            if (this.isOpenedRevision(this.companyid) && !this.isFinishedRevision(this.companyid)) {
                this.exitConfirmDialog = true;
                this.exitConfirm = next;
            } else {
                next();
            }

        },


    }
</script>

<style scoped>

    pre {
        font-size: 8px
    }

</style>
