<template>

    <div>
        <v-row justify="center">
            <v-col cols="12" sm="6">
                <LoadOrErrorDialog :loading="loading" :serverMessage="serverMessage" :serverError="serverError" @clear:error="onClearError"></LoadOrErrorDialog>
                <booking-item 
                    @deleted="onBookingItemDeleted" 
                    @updated="onBookingItemUpdated" 
                    v-if="!(loading||!bookingsList||bookingsList.length>=1)" 
                    :bookingitem="{subject_id: companyid, company: company }" 
                />
                <booking-item 
                    @deleted="onBookingItemDeleted" 
                    @updated="onBookingItemUpdated" 
                    v-else-if="!serverError" 
                    v-for="booking in bookingsList" 
                    :key="booking.id" 
                    :bookingitem="booking"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>

    import BookingItem from "./BookingItem";
    import LoadOrError from "../misc/LoadOrError";
    import LoadOrErrorDialog from "../misc/LoadOrErrorDialog";

    export default {
        name: "BookingPageCompanyDetails",
        components: {LoadOrErrorDialog, LoadOrError, BookingItem},
        props: ['companyid'],
        data() {
            return {
                bookingsList: [],
                loading: true,
                company: {},
                serverMessage: '',
                serverError: false,
            };
        },
        created() {
            this.fetchBookings();
        },
        computed: {},
        methods: {

            onClearError(){
                this.serverError = false;
                this.serverMessage = '';
                this.$router.back();
            },

            onError(err){
                this.serverMessage = this.parseServerError(err);
                this.serverError = true
            },

            onBookingItemUpdated(item) {

                let indexOfItem = _.findKey(this.bookingsList, o => o.id === item.id);
                this.bookingsList.splice(indexOfItem, 1, item);

            },

            onBookingItemDeleted(item) {

                let indexOfItem = _.findKey(this.bookingsList, o => o.id === item.id);
                this.bookingsList.splice(indexOfItem, 1);

            },

            fetchBookings() {

                this.loading = true;

                axios.get(`/api/company/${this.companyid}`)
                    .then(({data}) => {
                        this.company = data;
                        return axios.get('/api/bookings', {params: {futurescope: true, company: this.companyid}})
                    })
                    .then(({data}) => {
                        this.bookingsList = data;
                    })
                    .catch((res) => {
                        this.onError(res);
                    })
                    .then(() => {
                        this.loading = false;
                    })

            },

        }
    }
</script>

<style scoped>

</style>
