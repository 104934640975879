<template>
    <div>
        <v-card class="px-2 py-1" elevation="2">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <h2 class="headline mb-6">Revisionstyper</h2>
                        <v-row v-for="t in activeTypesForArea(selectedRevision.area_id)" :key="t.id" align="center" class="mb-3 flex-wrap">
                            <v-col cols="6" md="4">
                                <h3 class="title">{{ t.short_name }}</h3>
                                <template v-if="latestReportStatus = latestReportStatusForType(t.id)">
                                    <h4 class="font-medium mb-0 mt-1">Tidigare revision</h4>
                                    <span>{{ latestReportStatus }}</span>
                                </template>
                            </v-col>
                            <v-col cols="6" md="8">
                                <v-switch
                                    :input-value="!!selectedRevision.types.find(o => o.id === t.id)"
                                    v-if="selectedRevision" :label="''"
                                    @change="onTypeSwitchClick(t, $event)"
                                    class="input-switch--default"
                                    inset
                                ></v-switch>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>

<script>

    import {mapGetters, mapState, mapMutations} from 'vuex'
    import DebugSheet from "../../misc/DebugSheet";


    export default {
        name: "SelectTypesForm",
        components: {DebugSheet},
        computed: {
            ...mapState(['openedRevisions']),
            ...mapGetters(['selectedRevision', 'isselectedRevision', 'selectedRevisionReports', 'activeTypesForArea']),

        },

        methods: {

            latestReportStatusForType(type) {
                let report = this.selectedRevisionReports.find(o => o.revision_type.id == type);

                if (_.isUndefined(report)) {
                    return false;
                }

                return report && report.revision_class && `${report.revision_class.value}, ${report.date}`;
            },

            onTypeSwitchClick(type, checked) {

                if (!checked) {
                    this.removeRevisionType(type);
                } else {
                    this.addRevisionType(type);
                }

            },

            ...mapMutations([
                'removeRevisionType', 'addRevisionType'
            ]),

        }
    }
</script>

<style scoped>

</style>
