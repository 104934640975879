<template>

    <div class="mt-3">

        <div style="display: flex; align-items: center;" class="mb-2">
            <h3 class="subtitle-1 font-medium">{{ displayName }}a</h3>
            <template v-if="contactsWithResponsibility.length > 0">
                <a @click="showDialog=true" class="ml-4">
                    Ändra
                </a>
            </template>
        </div>
        <template v-if="contactsWithResponsibility.length > 0">
            <div v-for="c in contactsWithResponsibility" :key="c.id" class="mb-3">
                <div>
                    {{ c.name }}
                </div>
                <span class="text-grey300">{{ c.email }}</span>
            </div>
        </template>
        <template v-else>
            Det saknas ansvarig men du kan <a href="#" @click="showDialog=true">lägga till en ny</a>.
        </template>

        <v-dialog v-model="showDialog" max-width="600px">

            <v-card>
                <v-card-title class="d-flex align-items-center justify-space-between">
                    <h2>{{ displayName }}</h2>
                    <v-btn outlined color="primary" small @click="makeNewContact">Ny kontakt</v-btn>
                </v-card-title>
                <v-card-text>
                    <v-list style="padding-top: 0;" is="transition-group" name="list" class="mt-2">
                        <v-list-item
                            v-for="contact in contactsWithResponsibility"
                            :key="contact.id"
                            style="padding: 0;"
                        >

                            <v-list-item-content>
                                <v-list-item-title v-html="contact.name" class="mb-1"></v-list-item-title>
                                <v-list-item-subtitle class="mb-1">{{ contact.email }}</v-list-item-subtitle>
                                <v-list-item-subtitle>{{ contact.tele }}</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon @click="removeResponsibility(contact)" v-on="on">
                                            <v-icon :color="'accent'">mdi-arrow-down</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Ta bort ansvarsområde</span>
                                </v-tooltip>
                            </v-list-item-action>
                            <v-list-item-action>
                                <v-btn text @click="editContactId = contact.id">
                                    Ändra
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>

                    <h3 class="subtitle-1 mt-4">Övriga kontaktpersoner</h3>
                    <v-list style="padding-top: 0;">
                        <v-list-item
                            v-for="contact in contactsWithoutResponsibility"
                            :key="contact.id"
                            style="padding: 0;"
                        >

                            <v-list-item-content>
                                <v-list-item-title v-html="contact.name"></v-list-item-title>
                                <v-list-item-subtitle class="mb-1">{{ contact.email }}</v-list-item-subtitle>
                                <v-list-item-subtitle>{{ contact.tele }}</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon @click="addResponsibility(contact)" v-on="on">
                                            <v-icon :color="'accent'">mdi-arrow-up</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Gör till <span class="lowercase">{{ displayName }}</span></span>
                                </v-tooltip>
                            </v-list-item-action>

                            <v-list-item-action>
                                <v-btn text @click="editContactId = contact.id">
                                    Ändra
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="showDialog = false">Stäng</v-btn>
                </v-card-actions>
            </v-card>


            <EditContactDialog :defaultTypes="selectedRevision.types.map(o => o.id)"
                               :contact-id.sync="editContactId"></EditContactDialog>

        </v-dialog>


    </div>


</template>

<script>

    import {mapGetters, mapActions, mapMutations} from 'vuex';
    import EditContactDialog from "./EditContactDialog";


    export default {
        name: "AssignResponsibilityDialog",
        components: {EditContactDialog},
        props: {
            responsibility: {
                validator: function (value) {
                    return Vue.prototype.$lureg.contactResponsibilities
                        .map(r => r.value).indexOf(value) !== -1;
                },
                required: true
            },
            defaultTypes: {
                type: Array,
                required: true,
            }
        },
        data: () => ({
            showDialog: false,
            editContactId: null,
        }),

        computed: {

            ...mapGetters(['selectedRevisionContacts', 'selectedRevision']),

            contactsWithResponsibility() {
                return this.selectedRevisionContacts
                    .filter(c => c.types.some(t => t.pivot[this.responsibility]) == 1);

            },
            contactsWithoutResponsibility() {
                return this.selectedRevisionContacts
                    .filter(c => !c.types.some(t => t.pivot[this.responsibility]) == 1);
            },

            displayName() {

                return this.$lureg.contactResponsibilities
                    .find(i => i.value === this.responsibility)
                    .text;
            },

        },

        methods: {
            ...mapMutations(['createContact', "updateContact"]),
            ...mapActions([]),

            addResponsibility(contact) {

                let contactClone = _.cloneDeep(contact);

                this.addNewTypesToContact(this.defaultTypes, contactClone);

                contactClone.types
                    .filter(t => this.defaultTypes.includes(t.id))
                    .map(t => t.pivot = {...t.pivot, [this.responsibility]: 1});

                this.updateContact(contactClone);

            },


            removeResponsibility(contact) {

                let contactClone = _.cloneDeep(contact);

                contactClone.types.map(t => t.pivot = {...t.pivot, [this.responsibility]: 0});

                this.updateContact(contactClone);

            },

            makeNewContact() {

                let newContact = this.newContactModelWithResponsibility(this.defaultTypes, this.responsibility);

                this.createContact(newContact);

            }

        }
    }
</script>

<style scoped>

</style>
