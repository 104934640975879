<template>
    <v-layout align-center row wrap v-if="visible">
        <v-col cols="12">
            <Scanner :companyid="selectedRevisionId" :category="category" :companyname="selectedRevision.company || ''"/>
        </v-col>
    </v-layout>
</template>

<script>
    import {mapGetters, mapState, mapMutations} from 'vuex';

    import Scanner from "../../../misc/scanner/Scanner";
    export default {
        name: "PhotoMetadata",
        components: {Scanner},
        props: ['category', 'metadata'],

        computed: {
            ...mapState(['selectedRevisionId']),
            ...mapGetters(['selectedRevision']),
            visible() {
                const METADATA_ID = 7;
                return this.metadata && this.metadata.some(o => o.id == METADATA_ID);
            },
        }
    }
</script>

<style scoped>

</style>
