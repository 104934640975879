import RevisionPage from "../components/RevisionPage/RevisionPage";
import BookingPage from "../components/BookingPage/BookingPage";
import BookingPageCompanyDetails from "../components/BookingPage/BookingPageCompanyDetails";
import MainPageOverview from "../components/MainPage/MainPageOverview";
import MainPageAreaDetails from "../components/MainPage/MainPageAreaDetails";
import MainPageMap from "../components/MainPage/MainPageMap";
import Login from "../components/MainPage/Login";
import store from '../store/luregStore.js'


const routes = [
    {
        path: '/revision/:companyid/:step',
        props: true,
        name: 'revisionpage',
        component: RevisionPage,
    },
    {
        path: '/booking',
        name: 'booking',
        props: true,
        component: BookingPage,
        children: [{
            path: 'company/:companyid',
            name: 'bookingdetails',
            component: BookingPageCompanyDetails,
            props: true,
        },
        ],
    },
    {
        path: '/area/:areaid',
        name: 'area',
        props: true,
        component: MainPageAreaDetails
    },
    {
        path: '/area/map',
        name: 'areamap',
        props: true,
        component: MainPageMap
    },
    {
        path: '/overview',
        name: 'overview',
        props: false,
        component: MainPageOverview
    },
    {
        path: '/login',
        name: 'login',
        props: false,
        component: Login
    },


    {path: '*', redirect: store.state.selectedAreaId === -1
            ? '/overview' : `/area/${store.state.selectedAreaId}`},
];

export default routes;
