<template>
    <div>
        <v-row>
            <v-col cols="12" md="6">
                <div v-if="openedNotFinishedRevisions.length > 0" class="mb-6">
                    <v-card class="px-2 py-1" elevation="2">
                        <v-card-text>
                            <h2 class="headline mb-3">Pågående revisioner</h2>
                            <v-list three-line class="mb-4 list-with-action">
                                <OpenedRevisionCard v-for="ftg in openedNotFinishedRevisions" :key="ftg.original.id" :ftg="ftg"/>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </div>
                <template v-show="pendingUploadsCount > 0">
                    <DocumentsUploadCard @countUpload="pendingUploadsCount = $event" />
                </template>
            </v-col>
            <v-col cols="12" md="6">
                <v-card class="px-2 py-1" elevation="2">
                    <v-card-text v-if="hasFinishedRevisions || (revisionHistory && revisionHistory.length > 0)">
                        <h2 class="headline mb-4">Avslutade revisioner</h2>

                        <template v-if="finishedRevisions.length">
                            <h3>Inte uppladdade ({{ finishedRevisions.length }})</h3>

                            <v-list three-line class="mb-4 list-with-action">
                                <FinishedRevisionCard v-for="ftg in finishedRevisions" :key="ftg.original.id" :ftg="ftg"/>
                            </v-list>
                        </template>

                        <template v-if="(revisionHistory && revisionHistory.length > 0) || loadingHistoryError">
                            <v-divider v-if="finishedRevisions.length"></v-divider>

                            <div v-if="loadingHistoryError">
                                <v-alert class="my-3 lighten-3"
                                        type="error"
                                        transition="scale-transition"
                                >
                                    <h2>Kunde inte hämta revisionshistorik</h2>
                                    <h3>{{ loadingHistoryError }}</h3>
                                    <v-btn @click="fetchHistory" color="primary">försök igen</v-btn>
                                </v-alert>
                            </div>

                            <v-list v-else three-line class="mb-3 mt-4 list-with-action">
                                <v-list-item class="pl-0 pr-0" v-for="history in revisionHistory" :key="history.hid">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ history.company.company }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ history.company.visitort || '' }}</v-list-item-subtitle>
                                        <v-list-item-subtitle class="mt-1">{{ getReadableDateString(history.date, false) }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-dialog v-model="sendReportDialog[history.hid]" persistent max-width="580px">
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined small color="primary" v-on="on">
                                                    Skicka rapport
                                                </v-btn>
                                            </template>
                                            <v-card>
                                                <v-card-title>
                                                    <h2>Revisionsrapport</h2>
                                                </v-card-title>
                                                <v-card-text>
                                                    <p>
                                                        Mottagarna får ett e-postmeddelande med alla rapporter.
                                                        Vi skickar även en kopia till din e-postadress.
                                                    </p>
                                                    <v-autocomplete
                                                        :disabled="isSendingReport[history.hid]"
                                                        v-model="selectedReportContacts[history.hid]"
                                                        :items="history.company.contacts"
                                                        item-text="name"
                                                        item-value="id"
                                                        label="Välj en eller flera mottagare"
                                                        multiple
                                                        outlined
                                                        class="mt-7"
                                                    />

                                                    <div v-if="sendReportErrormessage">
                                                        <v-sheet class="pa-3" color="error" dark>
                                                            <h3 class="subtitle-2">Rapporten kunde inte skickas</h3>
                                                            <div v-html="sendReportErrormessage || ''"></div>
                                                        </v-sheet>
                                                    </div>

                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-spacer/>
                                                    <v-btn color="primary" text :disabled="isSendingReport[history.hid]" @click="sendReportDialog[history.hid] = false">
                                                        Avbryt
                                                    </v-btn>
                                                    <v-btn color="primary" text
                                                        @click="sendRevisionReport(history.company, selectedReportContacts[history.hid], history.hid)">
                                                        {{isSendingReport[history.hid] ? 'Skickar rapport...' : sendReportErrormessage ?
                                                        'Försök igen' : 'Skicka rapport'}}
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </template>
                    </v-card-text>
                    <v-card-text v-else>
                        <h2 class="headline mb-4">Avslutade revisioner</h2>
                        <div class="d-flex align-items-center justify-center">
                            <RevisionIcon :width="150" height="136.51"></RevisionIcon>
                        </div>
                        <div class="text-center mt-6 mb-4">
                            Det finns ingen revisionshistorik att visa.
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>


    import {mapState} from 'vuex'
    import ConfirmDialog from "../misc/ConfirmDialog";
    import FinishedRevisionCard from "./Components/FinishedRevisionCard";
    import DocumentsUploadCard from "./Components/DocumentsUploadCard";
    import OpenedRevisionCard from "./Components/OpenedRevisionCard";
    import EmptyStateIcon from "../misc/EmptyStateIcon";
    import RevisionIcon from "../misc/RevisionIcon";
    import {Base64} from "js-base64";


    export default {
        name: "MainPageOverview",
        components: {
            OpenedRevisionCard, FinishedRevisionCard, DocumentsUploadCard, ConfirmDialog, EmptyStateIcon, RevisionIcon
        },
        data() {
            return {
                pendingUploadsCount: 0,
                sendReportDialog: {},
                isSendingReport: {},
                selectedReportContacts: {},
                loadingHistoryError: false,
                revisionHistory: [],
                sendReportErrormessage: null,

            }
        },
        created() {
            this.fetchHistory();
        },
        computed: {
            ...mapState(['openedRevisions']),
            hasFinishedRevisions() {
                return Object.keys(this.finishedRevisions).length > 0;
            },
            finishedRevisions() {
                return Object.values(this.openedRevisions).filter(o => o.finished);
            },
            openedNotFinishedRevisions() {
                return Object.values(this.openedRevisions).filter(o => !o.finished);
            },
        },
        watch: {
            finishedRevisions() {
                this.fetchHistory();
            }
        },
        methods: {

            fetchHistory() {

                this.loadingHistoryError = false;

                axios.get('/api/revisionhistory')
                    .then(({data}) => {
                        this.revisionHistory = data;
                    })
                    .catch(error => {
                        this.loadingHistoryError = this.parseServerError(error);
                    })

            },
            sendRevisionReport(company, selectedContacts, hid) {

                Vue.set(this.isSendingReport, hid, true);
                this.sendReportErrormessage = null;

                console.log(`skicka till ${company.id} med kontakter ${selectedContacts} `);

                let username = Base64.decode(localStorage.getItem("token")).split(":")[0];

                const skeUrl = `https://ske.servicezone.se/api/email/send-reports/${company.id}`;
                const sebaDator = `http://192.168.109.244:8080/api/email/send-reports/${company.id}`;
                axios.post(skeUrl, {
                    inspector_username: username,
                    contact_ids: selectedContacts
                })
                    .then(({data}) => {
                        Vue.set(this.sendReportDialog, hid, false);
                        this.$store.commit('snackbar', true);
                        this.$store.commit('snackbarContent', `Rapporten har skickats till ${company.company}.`);
                    })
                    .catch((res) => {
                        this.sendReportErrormessage = this.parseServerError(res);
                    })
                    .then(() => {
                        Vue.set(this.isSendingReport, hid, false);
                    })
            },

        }

    }
</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>
