<template>
    <div>
        <h3 class="subtitle-1 font-medium mb-4">
            Dokument <span v-if="documents && numberOfDocuments > 0">({{ numberOfDocuments }})</span>
        </h3>
        <div v-if="documents && numberOfDocuments > 0">
            <v-btn outlined small color="accent" @click="createDocument" class="mb-5">
                Nytt dokument
            </v-btn>
            <v-divider></v-divider>
            <v-row class="mt-4">
                <v-col cols="4" v-for="item in documents" :key="item.id">
                    <Document :filecategory="item.fileCategory" :image="item.image" @filecategorychanged="changeDocumentCategory" @delete="deleteDocument" @input="replaceDocument" :id="item.id"></Document>
                </v-col>
            </v-row>
        </div>
        <div v-else>
            <p>Det finns inga dokument till frågan än.</p>
            <v-btn outlined small color="accent" @click="createDocument" class="mb-5">
                Nytt dokument
            </v-btn>
        </div>
    </div>
</template>

<script>
    import Document from "./Document";
    import { get as dbGet, set as dbSet, del as dbDel } from 'idb-keyval';

    export default {
        name: "Scanner",
        components: {Document},
        data() {
            return {
                documents : [],
            }
        },
        props: {
            companyid: {
                required: true,
            },
            category: {
                required: true,
            },
            companyname: {
                required: true,
            }
        },
        watch: {
            category() {
                this.loadDocumentsFromIndexedDB();
            }
        },

        created(){
            this.loadDocumentsFromIndexedDB();
        },
        methods: {
            async loadDocumentsFromIndexedDB() {
                this.documents =
                    (await dbGet("documents") || [])
                    .filter(o => o.category === this.category)
                    .filter(o => Number(o.companyid) === Number(this.companyid))
                    .reverse();

            },
            async createDocument() {
                console.log("create image");
                let documents = await dbGet("documents") || [];
                documents.push({
                    id: this.obtainNegativeSerialId(),
                    category: this.category,
                    fileCategory: 1,
                    image: '',
                    companyid: this.companyid,
                    companyname: this.companyname
                });
                await dbSet("documents", documents);
                this.loadDocumentsFromIndexedDB();

            },
            async replaceDocument(id, image, fileCategory) {
                console.log("replace", id, fileCategory);
                let documents = await dbGet("documents") || [];
                let doc = {category: this.category, fileCategory, image, id, companyid: this.companyid, companyname: this.companyname};
                let idx = documents.findIndex(d => d.id === doc.id);
                doc.fileCategory = fileCategory ? fileCategory : ((documents[idx] || {}).fileCategory || 1);
                documents[idx] = doc;
                await dbSet("documents", documents);
            },
            async deleteDocument(id) {
                console.log("delete image", id);
                let documents = await dbGet("documents") || [];
                let idx = documents.findIndex(d => d.id === id);
                documents.splice(idx, 1);
                await dbSet("documents", documents);
                this.loadDocumentsFromIndexedDB();
            },
            async changeDocumentCategory({id, filecat}){
                console.log("change file category");
                let documents = await dbGet("documents") || [];
                let doc = documents.find(o => o.id === id);
                this.replaceDocument(doc.id, doc.image, filecat);
            },

        },
        computed: {
            numberOfDocuments() {
                return _.size(this.documents);
            }
        }
    }
</script>

<style scoped>

</style>
