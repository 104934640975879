<template>
    <v-card class="px-2 py-1" elevation="2">
        <v-card-text>
            <header class="d-flex align-items-center justify-space-between mb-4">
                <h2 class="headline mb-0 mt-0">Dokument</h2>
                <v-spacer></v-spacer>
                <v-dialog v-if=""
                    v-model="showCreateNewDocumentDialog"
                    width="500"
                >
                    <template v-slot:activator="{ on }">
                        <v-btn class="mr-2" outlined small color="primary" v-on="on" @click="createDocument">
                            Lägg till nytt
                        </v-btn>
                    </template>

                    <v-card v-if="newDocument">
                        <v-card-title
                            class="headline"
                            primary-title
                        >
                            Lägg till dokument
                        </v-card-title>

                        <v-card-text>

                        <v-select
                            v-model="newDocument.companyid"
                            :items="recentCompanies"
                            item-text="name"
                            item-value="id"
                            label="Företag"
                            no-data-text="Inget företag är aktuellt"
                        />

                        </v-card-text>

                        <Document :filecategory="newDocument.category" :image="newDocument.image" @filecategorychanged="changeDocumentCategory" @input="replaceDocument" :id="newDocument.id" :hideDeleteButton="true" />
                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer/>
                            <v-btn
                                color="primary"
                                text
                                @click="cancel()"
                            >
                                Avbryt
                            </v-btn>
                            <v-btn
                                color="primary"
                                text
                                :disabled="!newDocument || !newDocument.companyname || !newDocument.image || !newDocument.fileCategory"
                                @click="save()"
                            >
                                Spara
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-btn outlined small color="primary" @click="upload" :disabled="uploading > 0" v-if="documents.length">
                    {{ uploading ? 'Laddar upp' : 'Ladda upp'}}
                </v-btn>
            </header>
            <template v-if="documents.length">
                <h3 class="mb-0">Inte uppladdade ({{ documents.length }})</h3>
                <v-row justify="space-between" class="mt-2">
                    <v-col cols="6" v-for="document in documents" :key="document.id">
                        <v-card>
                            <v-img
                                :src="document.image"
                                ref="imageUrl"
                                aspect-ratio="1"
                                height="140"
                            ></v-img>
                            <v-card-text>
                                <h3 class="caption uppercase">{{ document.companyname || '' }}</h3>
                                <h4 class="title ">{{ getFileCategoryName(document.fileCategory) }}</h4>
                            </v-card-text>
                            <v-card-actions>
                                <v-progress-linear v-show="uploading && !uploadFailed[document.id]" v-model="uploadProgress[document.id]"/>
                                <div v-if="uploadFailed[document.id]">Fel!</div>

                                <v-btn text small color="primary" @click="deleteDocuments([document.id])" :disabled="uploading > 0">
                                    Ta bort
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
            <template v-else>
                <div class="d-flex align-items-center justify-center">
                    <EmptyStateIcon></EmptyStateIcon>
                </div>
                <div class="text-center mt-6 mb-4">
                    Inga dokument att ladda upp.
                </div>
            </template>
        </v-card-text>
    </v-card>
</template>

<script>

    import {TaskQueue} from 'cwait';
    import { get as dbGet, set as dbSet } from 'idb-keyval';
    import EmptyStateIcon from "../../misc/EmptyStateIcon";
    import {mapState} from "vuex";
    import Document from "../../misc/scanner/Document";

    export default {
        name: "DocumentsUploadCard",
        components: {Document, EmptyStateIcon},
        props: ['ftg', 'revisionHistory'],
        data() {
            return {
                documents: [],
                uploadProgress: {},
                uploadFailed: {},
                uploading: 0,
                showCreateNewDocumentDialog: false,
                newDocument: {
                    id: null,
                    category: null,
                    fileCategory:null,
                    image: null,
                    companyid: null,
                    companyname: null
                },
            }
        },
        created() {
            this.loadDocumentsFromIndexedDB();
        },
        watch: {
            newDocument: {
                handler(val){
                    if (this.newDocument && this.recentCompanies) {
                        let selectedCompany = (this.recentCompanies || []).find(i => i.id == this.newDocument.companyid);
                        if (selectedCompany) {
                            this.newDocument.companyname = selectedCompany.name;
                        }
                    }
                },
                deep: true
            }
        },
        computed: {
            ...mapState(['form', 'openedRevisions']),
            recentCompanies() {
                return Object.values(this.openedRevisions)
                    .map(o => {
                        return {id: o.delta.id, name: o.delta.company}
                    })
                    .concat((this.revisionHistory || []).map(o => {
                        return {id: o.company.id, name: o.company.company}
                    }));
            }

        },
        methods: {

            async save() {
                this.showCreateNewDocumentDialog = false;
                this.documents.push(this.newDocument);
                await dbSet("documents", this.documents);
            },
            cancel(){
                this.showCreateNewDocumentDialog = false;
                this.newDocument = {};
            },
            changeDocumentCategory({id, filecat}){
                console.log("change file category");
                this.newDocument.fileCategory = filecat;
            },

            replaceDocument(id, image, fileCategory) {
                console.log("replace", id, fileCategory);
                this.newDocument.image = image;
                this.newDocument.fileCategory = fileCategory;

            },

            async createDocument(){

                this.newDocument = {
                    id: this.obtainNegativeSerialId(),
                    category: 'efterbild',
                    fileCategory: 1,
                    image: null,
                    companyid: -1,
                    companyname: null
                };
                //this.documents.push(this.newDocument);
                //await dbSet("documents", this.documents);
                //this.loadDocumentsFromIndexedDB();
            },
            async loadDocumentsFromIndexedDB() {
                this.documents = await dbGet("documents") || [];
                this.$emit('countUpload', this.documents.length);
            },
            deleteDocuments(ids) {
                dbGet("documents").then((docs) => {
                    dbSet("documents", docs.filter(o => !ids.includes(o.id))).then(() =>{
                        this.loadDocumentsFromIndexedDB();
                    }).catch(console.error);
                }).catch(console.error);

            },

            async upload() {

                const uploadProgress = (docid) => ({loaded, total}) => {
                    console.log(`progress ${docid} ${loaded} ${total}`);
                    this.uploadProgress[docid] = Math.floor(100 * (loaded / total));
                };

                let queue = new TaskQueue(Promise, 3);

                this.uploadFailed = {};
                this.uploadProgress = {};
                let uploaded = [];

                await Promise.all(this.documents.map(queue.wrap(async doc => {

                        ++this.uploading;

                        console.log(`post ${doc.id}`);

                        await axios.post('/api/upload/images', doc, {onUploadProgress: uploadProgress(doc.id)})
                            .then(() => {
                                console.log(`uppladdning av ${doc.id} lyckades`);
                                this.documents.splice(this.documents.findIndex(o => o.id === doc.id), 1);
                                uploaded.push(doc.id);
                            }, (err) => {
                                console.error(`error ${this.parseServerError(err)} ${doc.id}`);
                                this.uploadFailed[doc.id] = true;
                            });

                        console.log(`postning klar av ${doc.id}`);

                        --this.uploading;

                    }
                )));

                this.deleteDocuments(uploaded);

            },
            getFileCategoryName(categoryid) {
               let categories = this.$store.state.form.file_categories;

               if (_.isUndefined(categories) || !categories) {
                   return '';
               }

               let category = categories.filter(cat => cat.id == categoryid);
               return !_.isUndefined(category[0]) ? category[0].name : '';
            },
        }
    }
</script>

<style scoped>

    #removebutton{
        display: none;
    }

</style>
