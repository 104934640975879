<template>

    <div>
        <v-card class="px-2 py-1" elevation="2">
            <v-container>
                <v-row>
                    <v-col cols="12">
                          <header class="mb-5 py-3 d-flex align-items-center justify-space-between">
                            <div>
                                <h2 class="headline mb-0">Kontaktpersoner</h2>
                            </div>
                            <div class="ml-4">
                                <v-btn outlined color="primary" small @click="makeNewContact">Ny kontakt</v-btn>
                            </div>
                          </header>
                         <v-simple-table class="table--default mb-10">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">Uppgifter</th>
                                        <th class="text-left">Revisionstyper</th>
                                        <th class="text-left">Ansvarar för</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody is="transition-group" name="list">
                                    <tr v-for="contact in selectedRevisionContacts.reverse()" :key="contact.id">
                                        <td>
                                            {{ contact.name }}
                                            <div v-show="contact.email" class="mt-1">
                                                {{ contact.email }}
                                            </div>
                                            <div v-show="contact.tele" class="mt-1">
                                                {{ contact.tele }}
                                            </div>
                                        </td>
                                        <td class="lowercase">
                                            {{ contact.types.map(t => $store.state.form.types.find(x => x.id == t.id).short_name).join(", ") }}
                                        </td>
                                        <td>
                                            <div
                                                v-for="resp in $lureg.contactResponsibilities"
                                                :key="resp.value"
                                                v-show="contact.types.filter(t => t.pivot[resp.value]).length > 0"
                                                class="mb-1"
                                            >
                                                {{ resp.text }} för
                                                <span class="lowercase">
                                                    {{ contact.types.filter(t => t.pivot[resp.value]).map(t =>
                                                        $store.state.form.types.find(x => x.id == t.id).short_name).join(", ") }}
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <v-btn text small color="primary" @click="editContactId = contact.id">
                                                Ändra
                                            </v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>

                        <template v-if="selectedRevisionDeletedContacts.length > 0">
                            <h3 class="subtitle-1 font-medium">Borttagna kontaktpersoner</h3>
                            <v-simple-table class="table--default">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">Uppgifter</th>
                                            <th class="text-left">Revisiontyper</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="contact in selectedRevisionDeletedContacts" :key="contact.id">
                                            <td>
                                                {{ contact.name }}
                                                <div v-show="contact.email" class="mt-1">
                                                    {{ contact.email }}
                                                </div>
                                                <div v-show="contact.tele" class="mt-1">
                                                    {{ contact.tele }}
                                                </div>
                                            </td>
                                            <td class="lowercase">
                                                {{ contact.types.map(t => $store.state.form.types.find(x => x.id == t.id).short_name).join(", ") }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </template>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>

        <EditContactDialog :defaultTypes="selectedRevision.types.map(o => o.id)"
                           :contact-id.sync="editContactId"></EditContactDialog>

    </div>

</template>

<script>

    import {mapGetters, mapMutations, mapActions} from 'vuex'
    import EditContactDialog from "./Components/EditContactDialog";

    export default {
        name: "EditContactsForm",
        components: {EditContactDialog},
        data: () => ({
            editContactId: null,
        }),
        computed: {
            ...mapGetters(['selectedRevisionContacts', 'selectedRevisionDeletedContacts', 'selectedRevision']),
        },
        methods: {
            ...mapMutations(['createContact', "updateContact"]),
            ...mapActions([]),
            makeNewContact() {

                let newContact = this.newContactModel(this.selectedRevision.types.map(o => o.id));

                this.createContact(newContact);

            }
        }

    }
</script>

<style scoped>

</style>
