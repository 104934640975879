<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-card class="px-2 py-1" elevation="2">
        <v-card-text>
            <h2 class="caption uppercase">{{ ((booking || {}).company || {}).company }}</h2>
            <h3 class="title mb-6">Boka tid</h3>
            <ProgressDialog :model="sendingModal" message="Skickar bokningsbekräftelse"/>

            <v-dialog
                ref="dateDialog"
                v-model="datePickerModal"
                :return-value.sync="datePicker"
                persistent
                width="290px"
            >
                <template v-slot:activator="{ on }">
                    <v-text-field
                        v-on="on"
                        v-model="datePicker"
                        label="Datum"
                        outlined
                        readonly
                    />
                </template>
                <v-date-picker v-model="datePicker" scrollable :min="minAllowedStartDate()" :first-day-of-week="1" header-color="secondary" locale="sv-se">
                    <v-spacer/>
                    <v-btn text color="primary" @click="datePickerModal = false">Avbryt</v-btn>
                    <v-btn text color="primary" @click="$refs.dateDialog.save(datePicker)">Spara</v-btn>
                </v-date-picker>
            </v-dialog>

            <v-dialog
                ref="timeDialog"
                v-model="timePickerModal"
                :return-value.sync="timePicker"
                persistent
                width="290px"
            >
                <template v-slot:activator="{ on }">
                    <v-text-field
                        v-on="on"
                        v-model="timePicker"
                        label="Tid"
                        outlined
                        readonly
                    />
                </template>
                <v-time-picker v-model="timePicker" format="24hr" min="6:00" max="20:00" header-color="secondary">
                    <v-spacer/>
                    <v-btn text color="primary" @click="timePickerModal = false">Avbryt</v-btn>
                    <v-btn text color="primary" @click="$refs.timeDialog.save(timePicker)">OK</v-btn>
                </v-time-picker>
            </v-dialog>

            <v-textarea
                outlined
                name="input-notes"
                label="Intern notering"
                :value="booking.event_note"
                v-model="booking.event_note"
            />

            <v-alert v-if="slotTaken" text type="error" icon="mdi-information-outline">
                Det finns redan ett bokat besök på en annan verkstad {{ datePicker }} klockan {{ timePicker }}.
            </v-alert>

            <v-alert v-if="hasBookedVisit" text type="error" icon="mdi-information-outline">
                Verkstaden har redan ett bokat besök av {{ bookedVisitBy }} den {{ bookedVisitAt }}.
            </v-alert>

        </v-card-text>
        <v-card-actions>
            <v-btn text color="error" v-if="booking.id" @click="deleteBooking()" :loading="isDeleting" :disabled="isDeleting"><span
                slot="loader">Tar bort tid...</span> Avboka tid
            </v-btn>
            <v-spacer></v-spacer>
            <v-dialog
                v-model="previewEmailModal"
                v-if="!!booking.id && !formDirty"
                width="600"
            >
                <template v-slot:activator="{ on }">
                    <v-btn
                        @click="previewBookingConfirmationEmail"
                        v-on="on"
                        text
                        color="primary"
                    >
                        Skicka bekräftelse
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title class="mb-3">
                        <h2>Förhandsgranskning</h2>
                    </v-card-title>
                    <v-card-text class="pb-1">
                        <div v-if="previewContent" v-html="previewContent"/>
                        <div v-if="!previewContent">Hämtar förhandsgranskning...</div>
                        <v-progress-linear :indeterminate="true" v-if="!previewContent"/>
                        <v-divider class="mt-6"></v-divider>
                        <v-autocomplete
                            v-if="previewContent"
                            v-model="selectedContacts"
                            :items="contacts"
                            item-text="name"
                            item-value="id"
                            label="Välj en eller flera mottagare"
                            multiple
                            outlined
                            class="mt-8"
                        />

                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="previewEmailModal = false;">Avbryt</v-btn>
                        <v-btn text color="primary" @click="sendBookingConfirmationEmail">Skicka</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-btn text color="primary" v-if="formDirty" @click="!booking.id ? createBooking() : updateBooking()" :loading="isSaving" :disabled="isSaving"><span
                slot="loader">Sparar...</span> Spara bokning
            </v-btn>

            <v-dialog width="500" v-model="errorModal">
                <v-card>
                    <v-card-title></v-card-title>
                    <v-card-text>
                        <v-alert class="mt-3" type="error" transition="scale-transition" v-html="errorMessage"></v-alert>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer/>
                        <v-btn
                            color="primary"
                            text
                            @click="errorModal = false"
                        >
                            Stäng
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card-actions>
        <v-snackbar
            bottom
            dark
            :timeout="6000"
            color="success"
            v-model="successModal"
        >
            {{ successMessage }}
        </v-snackbar>
    </v-card>
</template>

<script>


import ConfirmDialog from "../misc/ConfirmDialog";
import ProgressDialog from "../misc/ProgressDialog";

export default {
        name: "BookingItem",
        components: {ProgressDialog, ConfirmDialog},
        props: {
            bookingitem: {
                required: true,
                type: Object
            }
        },
        data() {

            // för att få den ibland icke från början existerande event_note reaktiv.
            let booking = {...{event_note: "", id: false}, ...this.bookingitem};

            return {
                sentTo: [],
                selectedContacts: [],
                datePickerModal: false,
                timePickerModal: false,
                sendingModal: false,
                previewEmailModal: false,
                timePicker: null,
                datePicker: null,
                isSaving: false,
                isDeleting: false,
                formDirty: false,
                previewContent: null,
                errorMessage: null,
                successMessage: null,
                booking,
                slotTaken: false,
                hasBookedVisit: false,
                bookedVisitAt: '',
                bookedVisitBy: '',
            };
        },
        mounted() {

            if (this.booking.event_time) {
                this.timePicker = this.booking.event_time.slice(11, 16);
                this.datePicker = this.booking.event_time.slice(0, 10);
            }

        },
        watch: {

            timePickerModal(){this.formDirty = true; this.checkAvailability()},
            datePickerModal(){this.formDirty = true; this.checkAvailability()},
            'booking.event_note': function (newVal, oldVal) {
                this.formDirty = true
            },

        },
        computed: {

            contacts: function () {
                    return this.booking.company.contacts.map(c => {
                        if (this.sentTo.includes(c.email)) {
                            if (!c.name.endsWith("(skickad)")) {
                                c.name = c.name + " (skickad)"
                            }
                        }
                        return c;
                    })
                }
            ,
            errorModal: {
                get() {
                    return !!this.errorMessage
                },
                set(value) {
                    this.errorMessage = false;
                }
            },
            successModal: {
                get() {
                    return !!this.successMessage;
                },
                set(value) {
                    this.successMessage = false;
                }
            },
            postData: function () {
                return {

                    subject_id: this.booking.subject_id,
                    event_time: `${this.datePicker} ${this.timePicker}`,
                    event_note: this.booking.event_note
                }
            }
        },
        methods: {


            minAllowedStartDate() {
                return new Date().toISOString().slice(0, 10)
            },

            checkAvailability() {
                axios.post('/api/checkavailability', this.postData)
                    .then(({data}) => {
                        this.slotTaken = data.slotTaken;
                        this.hasBookedVisit = data.hasBookedVisit;
                        this.bookedVisitAt = data.bookedVisitAt;
                        this.bookedVisitBy = data.bookedVisitBy;
                    })
                    .catch((ignored) => {

                    })
            },
            createBooking() {

                this.isSaving = true;

                axios.post('/api/bookings', this.postData)
                    .then(({data}) => {
                        this.$emit('updated', data);
                        this.formDirty = false;
                    })
                    .catch((error) => {
                        this.errorMessage = this.parseServerError(error);
                    })
                    .then(() => this.isSaving = false)
            },

            updateBooking() {

                this.isSaving = true;

                axios.put('/api/bookings/' + this.booking.id, this.postData)
                    .then(({data}) => {
                        this.$emit('updated', data);
                        this.formDirty = false;
                    })
                    .catch((error) => {
                        this.errorMessage = this.parseServerError(error);
                    })
                    .then(() => this.isSaving = false)
            },

            deleteBooking() {

                this.isDeleting = true;

                axios.delete(`/api/bookings/${this.booking.id}`)
                    .then(({data}) => {
                        this.$emit('deleted', data);
                    })
                    .catch((error) => {
                        this.errorMessage = this.parseServerError(error);
                    })
                    .then(() => this.isDeleting = false)
            },
            previewBookingConfirmationEmail() {

                axios.post('/api/email/preview/booking_confirmation', {id: this.booking.id, contact: this.selectedContacts})
                    .then(({data}) => {
                        this.previewContent = data;
                    })
                    .catch((error) => {
                        this.errorMessage = this.parseServerError(error);
                    })

            },
            sendBookingConfirmationEmail() {

                this.sendingModal = true;

                axios.post('/api/email/booking_confirmation', {id: this.booking.id, contact: this.selectedContacts})
                    .then(({data}) => {
                        this.sentTo.push(...data.map(x => x.email));
                        this.successMessage = "Mail skickat till " + data.map(x => x.name + " (" + x.email + ") ").join(", ");
                    })
                    .catch((error) => {
                        this.errorMessage = this.parseServerError(error);
                    })
                    .then(() => this.previewEmailModal = this.sendingModal = false)

            }

        }
    }
</script>

<style scoped>

</style>
