<template>
    <GmapMap
        :center="{lat:58.213488 ,lng:15.987058}"
        :zoom="7"
        ref="mapRef"
        map-type-id="terrain"
        style="width: 100%; height: 680px"
    >
        <GmapInfoWindow
            :options="infoWindow.options"
            :position="infoWindow.position"
            :opened="infoWindow.open"
            @closeclick="infoWindow.open = false"
        >
            <div v-html="infoWindow.template"></div>
        </GmapInfoWindow>

        <GmapMarker
            :key="index"
            v-for="(m, index) in companymarkers"
            :position="m.position"
            :clickable="true"
            :icon="{url: !!m.company.visited_this_year ? 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png' : 'https://maps.google.com/mapfiles/ms/icons/red-dot.png'}"
            :draggable="false"
            @click="openInfoWindow(m)"
        />
    </GmapMap>

</template>

<script>

    import {mapState, mapGetters} from 'vuex'
    import {gmapApi} from 'vue2-google-maps'

    export default {
        name: "MainPageMap",

        data: function () {
            return {
                map: null,
                infoWindow: {
                    position: {lat: 0, lng: 0},
                    open: false,
                    template: '',
                    options: {
                        maxWidth: 250,
                        pixelOffset: {
                            width: 0,
                            height: -35
                        }
                    }
                }
            }
        },

        computed: {
            ...mapState(['companies']),
            google: gmapApi,
            bounds() {

                let bounds = this.selectedArea().bounds;
                let sw = new this.google.maps.LatLng({lat: bounds.sw.lat, lng: bounds.sw.lng});
                let nw = new this.google.maps.LatLng({lat: bounds.nw.lat, lng: bounds.nw.lng});
                return new this.google.maps.LatLngBounds(sw, nw);

            },
            companymarkers() {

                return this.companies.map((i) => {
                    return {
                        company: {
                            name: i.company,
                            visit_address: i.visitort || '',
                            next_visit: i.next_visit || null,
                            visited_this_year: !!i.visited_this_year || false,
                        },
                        position: {
                            lat: parseFloat(i.latitude),
                            lng: parseFloat(i.longitude)
                        },
                    };
                })

            },
        },

        methods: {

            ...mapGetters(['selectedArea']),

            openInfoWindow: function(item) {
                this.infoWindow.open = true;
                this.infoWindow.position = item.position;
                this.infoWindow.template = `
                    <h2 style="font-size: .9rem" class="mb-1">${item.company.name}</h2>
                    ${item.company.visit_address}
                `;

                if (item.company.next_visit !== null) {
                    this.infoWindow.template += `
                        <div class="mt-2">
                            Besök bokat ${item.company.next_visit}
                        </div>
                    `;
                }
            },

        },

        mounted: function () {

            this.$refs.mapRef.$mapPromise.then((map) => {
                this.map = map;
                this.map.setCenter(this.bounds.getCenter());
                this.map.fitBounds(this.bounds);
                this.google.maps.event.addListenerOnce(this.map, "idle", function () {
                    this.map.setZoom(8);
                }.bind(this));
            });




        },


    }
</script>

<style type="less" scoped>

</style>
