<template>
    <v-dialog
        v-model="model"
        persistent
        width="300"
    >
        <v-card
            color="primary"
            dark
        >
            <v-card-text>
                {{ message }}
                <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                ></v-progress-linear>
            </v-card-text>
        </v-card>
    </v-dialog>

</template>

<script>
    export default {
        name: "ProgressDialog",
        data() {
            return {};
        },
        props: {
            model: {
                type: Boolean,
                default: false,
            },
            message: {
                type: String,
                default: "Förlöper..."
            }
        }
    }
</script>

<style scoped>

</style>
