<template>
    <div>
        <v-list-item class="pl-0 pr-0">
            <v-list-item-content>
                <v-list-item-title>{{ ftg.delta.company }}</v-list-item-title>
                <v-list-item-subtitle>{{ ftg.delta.visitort }}</v-list-item-subtitle>
                <v-list-item-subtitle class="mt-1">{{ this.getReadableDateString(ftg.delta.revision_ended_at, true) }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action style="flex-direction: flex-row;">
                <ConfirmDialog
                    class="mr-4"
                    @accept="deleteRevision(ftg.original.id)"
                    title="Ta bort revisionen?"
                    message="Ändringarna är inte uppladdade och kommer att tas bort permanent."
                    no-message="Avbryt"
                    yes-message="Ta bort"
                    hasOutlinedBtn="true"
                >
                    Ta bort
                </ConfirmDialog>
                <v-btn outlined small color="primary" @click="upload" v-if="!uploaded && !failed" :disabled="uploading">
                    Ladda upp
                </v-btn>
                <v-btn color="error" v-if="failed" @click="upload">Uppladdning misslyckades - Försök igen<v-icon right dark>error_outline</v-icon></v-btn>
            </v-list-item-action>
        </v-list-item>

        <v-dialog
            v-model="uploading"
            hide-overlay
            persistent
            eager
            width="300"
        >
            <v-card color="secondary" dark>
                <v-card-title class="subtitle-1">Laddar upp revision...</v-card-title>
                <v-card-text>
                    <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {mapMutations} from 'vuex'
    import { compare } from 'fast-json-patch';
    import ConfirmDialog from "../../misc/ConfirmDialog";

    export default {
        name: "UploadRevisionCard",
        components: {ConfirmDialog},
        props: ['ftg'],
        data() {
            return {
                uploading: false,
                percent: 0,
                uploaded: false,
                failed: false,

            }
        },

        methods: {
            ...mapMutations(['deleteRevision']),

            upload() {

                this.uploading = true;
                let jsonPatch = compare(this.ftg.original, this.ftg.delta);
                let companyID = this.ftg.original.id;
                let payload = {upload: this.ftg.delta, jsonPatch: jsonPatch };
                payload.jsonPatch = jsonPatch;

                axios.put(`/api/company/${companyID}`, payload, {
                    onUploadProgress: ({loaded, total}) => {
                        this.percent = Math.floor(100 * (loaded / total));
                    },
                }).then(() => {
                    this.uploaded = true;
                    this.failed = false;
                    this.deleteRevision(companyID);
                    this.$store.commit('addRecentCompany', companyID);
                }).catch(({response}) => {
                    console.error(response);
                    this.failed = true;
                    this.percent = 0;
                }).then(() => {
                    this.uploading = false;
                });

            },
        }
    }
</script>

<style scoped>

</style>
