<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-card class="px-2 py-1" elevation="2">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <h2 class="headline mb-6">Adressuppgifter</h2>
                        <v-form ref="form" v-model="isFormValid">
                            <fieldset>
                                <legend>
                                    <h3 class="title mb-1">Besöksadress</h3>
                                </legend>
                                <v-row>
                                    <v-col cols="12" md="4" class="pb-0">
                                        <v-text-field
                                            v-model.trim="visitort_street"
                                            label="Adress"
                                            type="text"
                                            :rules="[rules.required]"
                                            outlined
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4" class="pb-0">
                                        <v-text-field
                                            v-model.trim="visitort_zip"
                                            label="Postnummer"
                                            type="text"
                                            v-mask="'### ##'"
                                            masked="true"
                                            :rules="[rules.required, rules.zip]"
                                            outlined
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4" class="pb-0">
                                        <v-text-field
                                            v-model.trim="visitort_city"
                                            label="Ort"
                                            type="text"
                                            :rules="[rules.required]"
                                            outlined
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </fieldset>
                            <fieldset>
                                <legend>
                                    <h3 class="title mt-2">Postadress</h3>
                                </legend>
                                <v-row>
                                    <v-col cols="12" class="pb-0">
                                        <v-checkbox
                                            class="my-auto"
                                            label="Samma som besöksadress"
                                            @change="onSameAsClick"
                                            v-model="useSamePostAdressAsVisitadress"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row v-show="!useSamePostAdressAsVisitadress">
                                    <v-col cols="12" md="4" class="pb-0">
                                        <v-text-field
                                            v-model.trim="postadress"
                                            label="Adress"
                                            type="text"
                                            outlined
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4" class="pb-0">
                                        <v-text-field
                                            v-model.trim="postnumber"
                                            label="Postnummer"
                                            :rules="[rules.zip]"
                                            v-mask="'### ##'"
                                            masked="true"
                                            type="text"
                                            outlined
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4" class="pb-0">
                                        <v-text-field
                                            v-model.trim="postcity"
                                            label="Ort"
                                            type="text"
                                            outlined
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </fieldset>
                            <fieldset>
                                <legend>
                                    <h3 class="title mt-3">Faktureringsadress (om den avviker från postadress)</h3>
                                </legend>
                                <div>

                                    <v-row>

                                        <v-col cols="12" md="6" class="pb-0">
                                            <v-text-field
                                                label="Mottagare"
                                                v-model.trim="invoice_receiver"
                                                type="text"
                                                outlined
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="12" md="6" class="pb-0">
                                            <v-text-field
                                                label="Referens"
                                                v-model.trim="invoice_reference"
                                                type="text"
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>


                                        <v-col cols="12" md="6" class="pb-0">
                                            <v-text-field
                                                label="Adress"
                                                v-model.trim="invoice_address1"
                                                type="text"
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" class="pb-0">
                                            <v-text-field
                                                label="Adressrad 2"
                                                v-model.trim="invoice_address2"
                                                type="text"
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="6" class="pb-0">
                                            <v-text-field
                                                label="Postnummer"
                                                v-mask="'### ##'"
                                                masked="true"
                                                v-model.trim="invoice_zip_code"
                                                :rules="[rules.zip]"
                                                type="text"
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" class="pb-0">
                                            <v-text-field
                                                label="Ort"
                                                v-model.trim="invoice_city"
                                                type="text"
                                                outlined
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </div>
                                <v-row>
                                    <v-col cols="12" md="6" class="pb-0">
                                        <v-text-field
                                            label="E-post för e-faktura"
                                            v-model.trim="invoice_email"
                                            type="text"
                                            :rules="[rules.email]"
                                            outlined
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </fieldset>
                        </v-form>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>

<script>

    import {mapGetters, mapState, mapMutations} from 'vuex';
    import {mask} from 'vue-the-mask';

    export default {
        name: "AddressForm",
        directives: {
            mask
        },
        props: ['companyid'],
        data: () => ({
            isFormValid: null,
            useSamePostAdressAsVisitadress: false,

            rules: {
                required: value => !!value || 'Fältet måste fyllas i.',
                zip: value => (/^\d{3}\s\d{2}\s*$/.test(value) || !value)|| 'Ange postnummer i formatet 123 45.',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return (pattern.test(value) || !value) || 'E-postadressen ska vara i formatet namn@example.com.'
                }
            }
        }),
        watch: {
            isFormValid(newValue){
                this.$emit('update:nextblocked', !newValue);
            }
        },
        mounted() {
            this.useSamePostAdressAsVisitadress =
                this.selectedRevision.postadress === this.selectedRevision.visitort_street
                && this.selectedRevision.postnumber === this.selectedRevision.visitort_zip
                && this.selectedRevision.postcity === this.selectedRevision.visitort_city;

            this.$refs.form.validate();
        },
        computed: {
            ...mapState(['openedRevisions']),
            ...mapGetters(['selectedRevision', 'isselectedRevision']),

            area_id: () => {
                return this.selectedRevision && this.selectedRevision.area_id;
            },

            postadress: {
                get() {
                    return this.selectedRevision.postadress;
                },
                set(value) {
                    this.$store.commit('setCompanyField', {postadress: value})
                },
            },

            postnumber: {
                get() {
                    return this.selectedRevision.postnumber;
                },
                set(value) {
                    this.$store.commit('setCompanyField', {postnumber: value})
                },
            },
            postcity: {
                get() {
                    return this.selectedRevision.postcity;
                },
                set(value) {
                    this.$store.commit('setCompanyField', {postcity: value})
                },
            },

            visitort_street: {
                get() {
                    return this.selectedRevision.visitort_street;
                },
                set(value) {
                    if (this.useSamePostAdressAsVisitadress) {
                        this.postadress = value;
                    }

                    this.$store.commit('setCompanyField', {visitort_street: value})
                },
            },

            visitort_zip: {
                get() {
                    return this.selectedRevision.visitort_zip;
                },
                set(value) {
                    if (this.useSamePostAdressAsVisitadress) {
                        this.postnumber = value;
                    }

                    this.$store.commit('setCompanyField', {visitort_zip: value})
                },
            },

            visitort_city: {
                get() {
                    return this.selectedRevision.visitort_city;
                },
                set(value) {
                    if (this.useSamePostAdressAsVisitadress) {
                        this.postcity = value;
                    }

                    this.$store.commit('setCompanyField', {visitort_city: value})
                },
            },

            invoice_address1: {
                get() {
                    return this.selectedRevision.invoice_address1;
                },
                set(value) {
                    this.$store.commit('setCompanyField', {invoice_address1: value})
                },
            },

            invoice_address2: {
                get() {
                    return this.selectedRevision.invoice_address2;
                },
                set(value) {
                    this.$store.commit('setCompanyField', {invoice_address2: value})
                },
            },
            invoice_city: {
                get() {
                    return this.selectedRevision.invoice_city;
                },
                set(value) {
                    this.$store.commit('setCompanyField', {invoice_city: value})
                },
            },

            invoice_zip_code: {
                get() {
                    return this.selectedRevision.invoice_zip_code;
                },
                set(value) {
                    this.$store.commit('setCompanyField', {invoice_zip_code: value})
                },
            },

            invoice_email: {
                get() {
                    return this.selectedRevision.invoice_email;
                },
                set(value) {
                    this.$store.commit('setCompanyField', {invoice_email: value})
                },
            },

            invoice_receiver: {
                get() {
                    return this.selectedRevision.invoice_receiver;
                },
                set(value) {
                    this.$store.commit('setCompanyField', {invoice_receiver: value})
                },
            },
            invoice_reference: {
                get() {
                    return this.selectedRevision.invoice_reference;
                },
                set(value) {
                    this.$store.commit('setCompanyField', {invoice_reference: value})
                },
            },
            invoice_customer_id: {
                get() {
                    return this.selectedRevision.invoice_customer_id;
                },
                set(value) {
                    this.$store.commit('setCompanyField', {invoice_customer_id: value})
                },
            },
        },

        methods: {

            onSameAsClick() {
                if (this.useSamePostAdressAsVisitadress) {
                    this.postadress = this.visitort_street;
                    this.postnumber = this.visitort_zip;
                    this.postcity = this.visitort_city;
                }
            },


            ...mapMutations([]),
        },

    }
</script>

<style scoped>


</style>
