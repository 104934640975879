<template>
    <div class="text-xs-center">

        <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">

            <slot name="default" slot="activator">

            </slot>

            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Debug</v-toolbar-title>
                </v-toolbar>
                <v-sheet class="mx-4 my-3 pa-2" color="secondary lighten-4" elevation="3">

                    <pre>{{ message }}</pre>
                </v-sheet>

            </v-card>
        </v-dialog>


    </div>
</template>

<script>
    export default {
        name: "DebugDialog",
        data() {
            return {
                dialog: false
            }
        },
        props: ['message']
    }
</script>

<style scoped>

    pre {
        font-size: 0.6em
    }

</style>
