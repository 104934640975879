<template>
    <div class="text-xs-center">
        <v-dialog
            v-model="dialog"
            width="450"
        >
            <template v-slot:activator="{ on }">
                <v-btn :color="btnColor || 'accent'" dark v-on="on" v-if="!hasOutlinedBtn">
                    <slot name="default"></slot>
                </v-btn>
                <template v-else>
                    <v-btn color="error" outlined small v-on="on">
                        <slot name="default"></slot>
                    </v-btn>
                </template>
            </template>

            <v-card>
                <v-card-title>
                    <h2>{{ title }}</h2>
                </v-card-title>
                <v-card-text>
                    {{ message }}
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="noClick"
                    >
                        {{ noMessage }}
                    </v-btn>
                    <v-btn
                        color="red"
                        text
                        @click="yesClick"
                    >
                        {{ yesMessage }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: "ConfirmDialog",
        data() {
            return {
                dialog: false
            }
        },
        props: {
            title: {
                type: String,
                default: 'Är du säker?'
            },
            message: {
                type: String,
                default: ""
            },
            yesMessage: {
                type: String,
                default: "Fortsätt"
            },
            noMessage: {
                type: String,
                default: "Avbryt"
            },
            btnColor: {
                type: String,
                default: 'accent'
            },
            hasOutlinedBtn: {
                default: false
            }
        },
        methods: {
            yesClick() {
                this.dialog = false;
                this.$emit('accept')
            },
            noClick() {
                this.dialog = false
            }
        }
    }
</script>

<style scoped>

</style>
