import VueRouter from "vue-router";
import routes from "./routes";

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {

        // när man återgår till företagslistan, scrolla till sparad position när laddning av företag är klar
        if (savedPosition && to.name === 'area') {

            return new Promise((resolve, reject) => {

                let unwatch = router.app.$root.$watch('$store.state.companies', () => {
                    resolve(savedPosition);
                    unwatch();
                })
            });
        } else {
            return {x: 0, y: 0}
        }
    }
});

router.afterEach((to, from) => {
    if (router.app.$root.$store.state.drawer) {
        router.app.$root.$store.commit('drawer', false);
    }
});

export default router;
