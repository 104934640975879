<template>
    <div class="text-xs-center">
        <v-dialog
            v-model="show"
            width="500"
            persistent
        >
            <v-card>
                <v-card-title>En ny version av Lureg finns tillgänglig</v-card-title>
                <v-card-text></v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="doReload"
                    >
                        Uppdatera
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: "NewVersionAvailableDialog",
        props: ['show'],
        methods: {
            doReload() {
                window.location.reload();
            },

        }
    }
</script>

<style scoped>

</style>
