<template>
    <v-sheet v-if="showDebug && !hidden" @click.native="hidden = true" class="mx-auto my-3 pa-2" color="grey lighten-3" elevation="1">
        <slot></slot>
        <pre>{{ txt }}</pre>
    </v-sheet>
</template>

<script>
    import {mapState} from 'vuex';
    export default {
        name: "DebugSheet",
        data: () => ({
            hidden: false,
        }),
        props: ['txt'],
        computed: {
            ...mapState([
                'showDebug'
            ])
        }
    }
</script>

<style scoped>

</style>
