import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VuexPersistence from 'vuex-persist'


Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    // vilken state som ska vara persistent
    reducer: (state) => ({
            openedRevisions: state.openedRevisions,
            selectedRevisionId: state.selectedRevisionId,
            selectedAreaId: state.selectedAreaId,
            negativeSerialId: state.negativeSerialId,
            authToken: state.authToken,
            documents: state.documents,
        }
    ),
    // vilka mutators som ska mutera persistent state
    filter: mutation => ([
        'selectRevision', 'setCompanyField',
        'setSelectedAreaId', 'addRevisionType',
        'removeRevisionType', 'addEquipment',
        'removeEquipment', 'setForm','updateContact',
        'decrementNegativeSerialId', 'setCredentials',
        'setEnvReportComment', 'addEnvReportAnswer', 'removeEnvReportAnswer',
        'deleteRevision', 'finishRevision', 'resumeRevision',
        'setRevisionAspire'
        ].includes(mutation.type))
});



// om revisionstyp ändras, ändra också vilken klass revisionen aspirerar för
const guessAspirationPlugin = store => {

    store.subscribe((mutation, state) => {

        switch (mutation.type) {
            case 'selectRevision':
            case 'addRevisionType':
            case 'removeRevisionType':


                if (!state.openedRevisions[state.selectedRevisionId].delta.aspire) {
                    store.commit('setRevisionAspire', {})
                }

                // anta att revisionstypen ska vara godkänd
                // todo: fixa så att klassen väljs utifrån uppfyllda frågor i en senare version

                let aspire = {};

                state.openedRevisions[state.selectedRevisionId].delta.types.map(t => {

                    return state.form.classes
                        .filter(cls => cls.revision_type == t.id);

                }).flatMap(o => o)
                    .filter(o => o.class_approved)
                    .filter(o => !o.class_temp)
                    .filter(o => o.class_level == 1)
                    .forEach(o => {

                        // fix 2023-08-18, eftersom verkstadens klass inte ska vara förifylld alls, så sätt istället null här
                        // hela denna funktion/plugin tappar därmed sitt syfte men låt vara kvar tills vidare
                        // aspire[o.revision_type] = o.id;
                        aspire[o.revision_type] = null;

                    });

                store.commit('setRevisionAspire', aspire);

                break;

        }

    })
};



export default new Vuex.Store({

    strict: process.env.NODE_ENV !== 'production',
    plugins: [vuexLocal.plugin, guessAspirationPlugin],
    state: {
        drawer: false,
        newVersionAvailable: false,
        recentCompanies: [],
        authToken: '',
        areas: [],
        form: [],
        snackbar: false,
        snackbarContent: "",
        companies: [],
        position: {
            coords: {
                accuracy: 0,
                lat: 0,
                lng: 0,
            },
            timestamp: 0
        },
        positionUpdates: 0,
        location: {lat: 0, lng: 0},
        openedRevisions: {
//          id: {
//              original: data,
//              delta: data
//              startedAt: timestamp,
//          }
        },
        selectedRevisionId: -1,
        selectedAreaId: -1,
        showDebug: false,
        negativeSerialId: -1,

    },
    getters: {
        location: state => {
            return {lat: _.round(state.position.coords.latitude, 6), lng: _.round(state.position.coords.longitude, 6)}
        },
        accuracy: state => {
            return _.round(state.position.coords.accuracy, 2)
        },
        openedRevisionsCount: state => {
            return _.size(state.openedRevisions);
        },
        openedRevisionById: (state) => (id) => {
            return (state.openedRevisions[id] || {}).delta || null;
        },
        isOpenedRevision: (state) => (id) => {
            return !!state.openedRevisions[id];
        },
        isFinishedRevision: (state) => (id) => {
            return (state.openedRevisions[id] || {}).finished || false;
        },

        selectedRevisionDynamicForm: (state, getters) => {

            let formData = { categories: [], categories_length: 0 };

            if (!getters.isOpenedRevision || !state.form.categories){
                return formData;
            }

            let types = (getters.selectedRevision.types || []).flatMap(({id}) => id);

            formData.categories = state.form.categories
                .filter(cat => cat.types.flatMap(({id}) => id).some(i => types.includes(i)));

            formData.categories_length = formData.categories.length;

            return formData;

        },
        previousRevisionMetadata: (state, getters) => (metadataType, categoryId) => {

            return (state.openedRevisions[state.selectedRevisionId]?.original?.metadata || [])
                .find(m => m.metadata_type_id == metadataType && m.equipment_category_id == categoryId);

        },
        selectedRevisionMetadata: (state, getters) => (metadataType, categoryId) => {

            return (getters.selectedRevision.metadata || [])
                .find(m => m.metadata_type_id == metadataType && m.equipment_category_id == categoryId);

        },
        selectedRevision: state => {
            return (state.openedRevisions[state.selectedRevisionId] || {}).delta || {};
        },
        selectedRevisionContacts: (state, getters) => {
            return (getters.selectedRevision.contacts || [])
                .filter(c => c.state !== "deleted");
        },
        selectedRevisionDeletedContacts: (state, getters) => {
            return (getters.selectedRevision.contacts || [])
                .filter(c => c.state === "deleted");
        },
        selectedRevisionContactById: (state, getters) => (id) => {
            return getters.selectedRevisionContacts.find(c => c.id == id);
        },
        selectedRevisionReports: (state, getters) => {
            return (getters.selectedRevision.reports || []);
        },
        selectedRevisionEnvReportAnswers: (state) => {
            return ((state.openedRevisions[state.selectedRevisionId] || {}).delta || {}).envReportAnswers || [];
        },
        selectedRevisionEnvReportComment: (state) => {
            return ((state.openedRevisions[state.selectedRevisionId] || {}).delta || {}).envReportComment || '';
        },
        selectedArea: state => {
            return state.areas.find(o => o.cid == state.selectedAreaId) || {};
        },
        selectedAreaName: (state, getters) => {
            return getters.selectedArea.name || false;
        },
        activeTypesForSelectedArea: (state, getters) => {
            return getters.selectedArea.activeTypes || [];
        },
        activeTypesForArea: (state) => (id) => {
            return (state.areas.find(o => o.cid == id) || {}).activeTypes || [];
        },

    },
    mutations: {

        setCompanyField: function(state, payload){
            state.openedRevisions[state.selectedRevisionId].delta = Object.assign({}, state.openedRevisions[state.selectedRevisionId].delta, payload);
        },

        addRevisionType: function(state, type){
            state.openedRevisions[state.selectedRevisionId].delta.types.push(type);
        },

        removeRevisionType: function(state, type){
            let idx = state.openedRevisions[state.selectedRevisionId].delta.types.findIndex(o => o.id == type.id);
            state.openedRevisions[state.selectedRevisionId].delta.types.splice(idx, 1);
        },
        setEnvReportComment(state, comment){
            Vue.set(state.openedRevisions[state.selectedRevisionId].delta, 'envReportComment', comment);
        },
        addEnvReportAnswer: function (state, answer) {
            if (!state.openedRevisions[state.selectedRevisionId].delta.envReportAnswers) {
                Vue.set(state.openedRevisions[state.selectedRevisionId].delta, 'envReportAnswers', []);
            }
            state.openedRevisions[state.selectedRevisionId].delta.envReportAnswers.push(answer);
        },
        removeEnvReportAnswer: function (state, answer) {
            let idx = state.openedRevisions[state.selectedRevisionId].delta.envReportAnswers.findIndex(o => o == answer);
            state.openedRevisions[state.selectedRevisionId].delta.envReportAnswers.splice(idx, 1);
        },

        addEquipment: function(state, equipment){
            state.openedRevisions[state.selectedRevisionId].delta.equipments.push(equipment);
        },
        removeEquipment: function(state, equipment){
            let idx = state.openedRevisions[state.selectedRevisionId].delta.equipments.findIndex(o => o.bid == equipment.bid);
            state.openedRevisions[state.selectedRevisionId].delta.equipments.splice(idx, 1);
        },

        createMetadata: function (state, metadata) {
            console.log("create metadata", metadata);
            state.openedRevisions[state.selectedRevisionId].delta.metadata.push(metadata);
        },

        updateMetadata: function (state, metadata){

            let idx = state.openedRevisions[state.selectedRevisionId].delta.metadata.findIndex(o => o.id == metadata.id);

            Vue.set(state.openedRevisions[state.selectedRevisionId].delta.metadata[idx], 'json_data', metadata.json_data);

        },
        setRevisionAspire: function (state, aspire) {
            Vue.set(state.openedRevisions[state.selectedRevisionId].delta, 'aspire' , aspire);
        },
        createContact: function(state, contact){
            state.openedRevisions[state.selectedRevisionId].delta.contacts.push(contact);
        },
        updateContact: function(state, contact){

            let index = state.openedRevisions[state.selectedRevisionId].delta.contacts
                .findIndex(i => i.id == contact.id);

            contact =
                Object.assign({}, state.openedRevisions[state.selectedRevisionId].delta.contacts[index], contact);

            Vue.set(state.openedRevisions[state.selectedRevisionId].delta.contacts, index, contact);
        },
        setForm: function (state, payload) {
            state.form = payload;
        },
        setAreas: function (state, areas) {
            state.areas = areas;
        },
        setCompanies: function (state, payload) {
            state.companies = payload;
        },
        setSelectedAreaId: function (state, payload) {
            state.selectedAreaId = payload;
        },

        setShowDebug: function (state, payload) {
            state.showDebug = payload;
        },

        setPosition: function (state, position) {
            state.positionUpdates++;
            state.position = position;
        },

        selectRevision: function (state, company) {

            if (!state.openedRevisions[company.id]) {

                let startedAt = Date.now();

                Vue.set(state.openedRevisions, company.id, {original: _.cloneDeep(company), delta: company, startedAt: startedAt});
            }

            state.selectedRevisionId = company.id;
        },

        resumeRevision: function (state, companyid) {
            state.selectedRevisionId = companyid;
        },

        finishRevision: function (state, companyid) {

            Vue.set(state.openedRevisions[companyid], 'finished', true);

        },
        deleteRevision: function(state, companyid) {

            if (state.selectedRevisionId == companyid){
                state.selectedRevisionId = -1;
            }
            Vue.delete(state.openedRevisions, companyid);
        },

        decrementNegativeSerialId: function (state) {
            --state.negativeSerialId;
        },

        snackbar: function (state, visible) {

            state.snackbar = visible;

        },
        drawer: function (state, v) {

            state.drawer = v;

        },
        snackbarContent: function (state, content) {

            state.snackbarContent = content;

        },

        setNewVersionAvailable: function (state, available) {

            state.newVersionAvailable = available;

        },

        /**
         * För att det är ett glapp mellan cache och färsk data,
         * ibland vill man veta utan fördröjning vilka revisioner som
         * är precis gjorda. (typ blåbmarkeringen i företagslistan)
         * @param state
         * @param companyId
         */
        addRecentCompany: function (state, companyId) {

            state.recentCompanies.push(companyId);

        },



    },
    actions: {

        fetchAreas: function ({commit}) {

            axios.get('/api/list-areas').then(response => {
                commit('setAreas', response.data);
            });

        },

        fetchForm: function ({commit}){

            axios.get('/api/form')
                .then(result => {
                    commit('setForm', result.data);
                })

        },



    }
});

