<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-card class="px-2 py-1" elevation="2">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <header class="mb-6 py-3 d-flex align-items-center justify-space-between">
                            <div>
                                <h2 class="headline mb-0">Miljö</h2>
                            </div>
                            <div class="ml-4">
                                <v-dialog v-model="notesDialog" max-width="600px">
                                    <v-card>
                                        <v-card-title>
                                            <h4 id="label-input-comment" class="subtitle-1 mb-1">Noteringar</h4>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-textarea
                                                v-model="notes"
                                                name="input-comment"
                                                label=""
                                                outlined
                                                rows="6"
                                                value=""
                                                aria-labelledby="label-input-comment"
                                            />
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="primary" text @click="notesDialog = false">Avbryt</v-btn>
                                            <v-btn color="primary" text @click="notesDialog = false">Spara</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>

                                <v-dialog v-model="dialog" max-width="600px" v-if="selectedRevision.envreports.length > 0">
                                    <template v-slot:activator="{ on }">
                                        <v-btn outlined small :color="'primary'" v-on="on" class="ml-4">
                                             Historik
                                        </v-btn>
                                    </template>
                                    <v-card>
                                        <v-card-title>
                                            <h4 class="title mb-1">Historik</h4>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-tabs show-arrows>
                                                <v-tab
                                                    v-model="tabmodel"
                                                    v-for="report in selectedRevision.envreports"
                                                    :key="report.env_report_id"
                                                >
                                                    {{ report.env_report_year }}
                                                </v-tab>

                                                <v-tab-item
                                                    v-for="report in selectedRevision.envreports"
                                                    :key="report.env_report_id"
                                                >
                                                    <v-card flat>
                                                        <v-card-text>
                                                            <div v-for="question in $store.state.form.envquestions" :key="question.env_question_id">
                                                                <h5 class="subtitle-1">{{ question.env_question_title }}</h5>
                                                                <template v-if="report.answers.filter(o => o.env_question_id == question.env_question_id).length > 0">
                                                                    <p v-for="ans in report.answers.filter(o => o.env_question_id == question.env_question_id)" :key="ans.id">
                                                                        {{ ans.env_answer_title }}
                                                                    </p>
                                                                </template>
                                                                <template v-else>
                                                                    <p>Svar saknas</p>
                                                                </template>
                                                            </div>
                                                            <div v-if="report.env_report_comment">
                                                                <h5 class="subtitle-2">Kommentar</h5>
                                                                <p> {{ report.env_report_comment }}</p>
                                                            </div>

                                                            <v-main py-0>
                                                                <v-layout wrap>
                                                                    <v-flex v-for="(file, index) in report.files" :key="index" xs12>
                                                                        <v-card @click="downloadWithAxios(file, file.substring(file.lastIndexOf('/') + 1))">
                                                                            <v-list>
                                                                                <v-list-item>
                                                                                    <v-list-item-title>{{ file.substring(file.lastIndexOf('/') + 1) }}</v-list-item-title>
                                                                                    <v-list-item-icon>
                                                                                        <v-icon class="primary--text">mdi-download</v-icon>
                                                                                    </v-list-item-icon>
                                                                                </v-list-item>
                                                                            </v-list>
                                                                        </v-card>
                                                                    </v-flex>
                                                                </v-layout>
                                                            </v-main>

<!--                                                            <v-img :key="index" v-for="(file, index) in report.files.filter(o => o.toLowerCase().endsWith('jpeg') || o.toLowerCase().endsWith('jpg'))" :src="file"></v-img>-->

                                                        </v-card-text>
                                                    </v-card>
                                                </v-tab-item>
                                            </v-tabs>

                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="primary" text @click="dialog = false">Stäng</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </div>
                        </header>
                        <div v-for="question in $store.state.form.envquestions" :key="question.env_question_id">
                            <h3 class="title mt-3 mb-3">{{ question.env_question_title }}</h3>
                            <div v-for="answer in question.answers" :key="answer.env_answer_id">
                                <v-checkbox
                                    :input-value="hasAnswer(answer.env_answer_id)"
                                    :label="answer.env_answer_title"
                                    @change="clicked($event, answer.env_answer_id)"
                                    style="margin-top: 0;"
                                >
                                </v-checkbox>
                            </div>
                        </div>

                        <div class="mb-2 mt-3" style="display: flex; align-items: center;">
                            <h3 class="subtitle-1 font-medium mt-0 mb-0">
                                Kommentar
                            </h3>
                            <a @click="notesDialog = true" class="ml-4" v-if="hasNotes()">
                                Ändra
                            </a>
                        </div>
                        <div>
                            <template v-if="!hasNotes()">
                                <v-btn outlined small color="accent" @click.native.stop="notesDialog = true" class="mb-5 mt-2">
                                    Lägg till kommentar
                                </v-btn>
                            </template>
                            <template v-else>
                                <div class="mb-2">
                                    {{ notes }} <br>
                                </div>
                            </template>
                        </div>

                        <div class="mt-4">
                            <Scanner :companyid="selectedRevisionId" category="env" :companyname="this.selectedRevision.company || ''" />
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>

<script>

    import {mapGetters, mapState, mapMutations} from 'vuex';

    import Scanner from "../../misc/scanner/Scanner";

    export default {
        name: "EnvironmentForm",
        components: {Scanner},
        data: () => ({
            dialog: false,
            notesDialog: false,
            tabmodel: null,
        }),
        computed: {

            ...mapState(['selectedRevisionId']),
            ...mapGetters(['selectedRevision', 'selectedRevisionEnvReportAnswers', 'selectedRevisionEnvReportComment']),

            notes: {
                get() {
                    return this.selectedRevisionEnvReportComment;
                },
                set(value) {
                    this.$store.commit('setEnvReportComment', value)
                },
            },

        },
        methods: {

            forceFileDownload(response, title) {
                console.log(title)
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', title)
                document.body.appendChild(link)
                link.click()
            },
            downloadWithAxios(url, title) {
                axios({
                    method: 'get',
                    url,
                    responseType: 'arraybuffer',
                })
                    .then((response) => {
                        this.forceFileDownload(response, title)
                    })
                    .catch(() => console.log('error occured'))
            },
            downloadImageWithAxios(imageUrl, element){
                axios.get(imageUrl, { responseType:"blob" })
                    .then(function (response) {

                        let reader = new window.FileReader();
                        reader.readAsDataURL(response.data);
                        reader.onload = function() {

                            let imageDataUrl = reader.result;
                            element.setAttribute("src", imageDataUrl);

                        }
                    });
            },

            ...mapMutations(['addEnvReportAnswer', 'removeEnvReportAnswer']),

            clicked(checked, id) {
                if (checked) {
                    this.addEnvReportAnswer(id);
                } else {
                    this.removeEnvReportAnswer(id);
                }
            },

            hasAnswer(id) {
                return this.selectedRevisionEnvReportAnswers.includes(id);
            },

            hasNotes() {
                return this.notes && this.notes.trim().length !== 0;
            }
        }
    }
</script>

<style scoped>

</style>
